import React from "react";
import ReactDiff from "react-diff-viewer";
import { Button, Dialog } from "@blueprintjs/core";
import { useConfiguration } from "../../contexts/ConfigurationContext";
import { useEras } from "../../contexts/ErasContext";
import useLocalState from "../../hooks/useLocalState";
import { DialogProps } from "../DialogProps";

function DiffDialog({ isOpen, onClose }: DialogProps) {
  const { eras, remoteEras } = useEras();
  const beforeCode = JSON.stringify(
    remoteEras,
    Object.keys(remoteEras ?? {}).sort(),
    2,
  );
  const afterCode = JSON.stringify(eras, Object.keys(eras ?? {}).sort(), 2);
  const [splitView, setSplitView] = useLocalState("diff.splitview", false);
  const {
    configuration: { darkMode },
  } = useConfiguration();
  return (
    <Dialog
      style={{
        width: "max-content",
        maxHeight: "100vh",
        maxWidth: "100vw",
      }}
      isOpen={isOpen}
      onClose={onClose}
      title={
        <>
          Diff{" "}
          <Button
            icon={splitView ? "list" : "list-columns"}
            onClick={() => setSplitView(!splitView)}
          />
        </>
      }
    >
      <div style={{ width: "max-content", maxHeight: "100vh" }}>
        <ReactDiff
          oldValue={beforeCode}
          splitView={splitView}
          newValue={afterCode}
          hideLineNumbers
          extraLinesSurroundingDiff={5}
          useDarkTheme={darkMode}
          leftTitle="Before (remote)"
          rightTitle="After (local)"
        />
      </div>
    </Dialog>
  );
}

export default DiffDialog;
