import React, { useEffect } from "react";
import { EditableText, H1, Icon } from "@blueprintjs/core";
import { useEras } from "../contexts/ErasContext";
import { newEra } from "../utils";
import EraEditor from "./editors/EraEditor";

const useSaveShortcut = () => {
  const { publishChanges } = useEras();
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "s" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        publishChanges();
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, [publishChanges]);
};

function ErasEditor() {
  useSaveShortcut();
  const { eras, setEras } = useEras();

  return (
    <>
      <div style={{ padding: 16, gap: 16 }}>
        {eras?.map((era, index) => (
          <EraEditor eras={eras} index={index} era={era} key={era.id} />
        ))}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 8,
            padding: 6,
          }}
        >
          <Icon
            style={{ alignSelf: "baseline" }}
            size={28}
            icon={"chevron-right"}
          />
          <H1>
            <EditableText
              placeholder={"New era..."}
              key={eras?.length}
              onConfirm={(name) => {
                setEras([...eras!, newEra(name, eras?.length || 1)]);
              }}
            />
          </H1>
        </div>
      </div>
    </>
  );
}

export default ErasEditor;
