import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  InputGroup,
  Intent,
  Label,
  NumericInput,
} from "@blueprintjs/core";
import { DialogProps } from "./components/DialogProps";
import { useConfiguration } from "./contexts/ConfigurationContext";

function ConfigurationDialog({ isOpen, onClose }: DialogProps) {
  const { configuration, setConfiguration } = useConfiguration();
  const [localConfiguration, setLocalConfiguration] = useState(configuration);
  const submit = () => {
    setConfiguration({ ...localConfiguration });
  };
  useEffect(() => {
    if (isOpen) {
      setLocalConfiguration(configuration);
    }
  }, [configuration, isOpen]);

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title={"Settings"}>
      <div style={{ padding: 16 }}>
        <Label>
          Images url
          <InputGroup
            placeholder="Image URL..."
            leftIcon={"media"}
            onChange={(e) =>
              setLocalConfiguration({
                ...localConfiguration,
                imageServer: e.target.value,
              })
            }
            value={localConfiguration.imageServer}
            type={"text"}
          />
        </Label>
        <Label>
          Sound url
          <InputGroup
            placeholder="Sound URL..."
            leftIcon={"music"}
            onChange={(e) =>
              setLocalConfiguration({
                ...localConfiguration,
                soundServer: e.target.value,
              })
            }
            value={localConfiguration.soundServer}
            type={"text"}
          />
        </Label>
        <Label>
          Device width
          <NumericInput
            placeholder="Device width..."
            leftIcon={"arrows-horizontal"}
            onValueChange={(value) =>
              setLocalConfiguration({
                ...localConfiguration,
                deviceWidth: value,
              })
            }
            value={localConfiguration.deviceWidth}
            type={"number"}
          />
        </Label>
        <Label>
          Device height
          <NumericInput
            placeholder="Device height..."
            leftIcon={"arrows-vertical"}
            onValueChange={(value) =>
              setLocalConfiguration({
                ...localConfiguration,
                deviceHeight: value,
              })
            }
            value={localConfiguration.deviceHeight}
            type={"text"}
          />
        </Label>
        <Checkbox
          checked={localConfiguration.darkMode}
          onChange={() =>
            setLocalConfiguration({
              ...localConfiguration,
              darkMode: !localConfiguration.darkMode,
            })
          }
        >
          Dark mode
        </Checkbox>
        <Button intent={Intent.PRIMARY} onClick={submit}>
          Save
        </Button>
      </div>
    </Dialog>
  );
}

export default ConfigurationDialog;
