import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import "@blueprintjs/table/lib/css/table.css";
import TopBar from "./components/TopBar";
import { useConfiguration } from "./contexts/ConfigurationContext";
import { useEras } from "./contexts/ErasContext";
import "./App.css";
import { firebaseApp } from "./firebase";
import EditorScreen from "./screens/EditorScreen";
import LoadingScreen from "./screens/LoadingScreen";
import LoginScreen from "./screens/LoginScreen";
import NotAdminScreen from "./screens/NotAdminScreen";
import { getAuth } from "firebase/auth";
import { doc, getFirestore } from "firebase/firestore";

function AppContent() {
  const { eras } = useEras();
  const [user, loading] = useAuthState(getAuth(firebaseApp));
  const [userData, loading2] = useDocument(
    user?.uid ? doc(getFirestore(firebaseApp), "users", user?.uid) : undefined,
  );
  if (loading || loading2) {
    return <LoadingScreen />;
  }
  if (!user) return <LoginScreen />;
  if (!userData?.data()?.admin) return <NotAdminScreen />;
  return <>{!!eras && <EditorScreen />}</>;
}

const App = () => {
  const {
    configuration: { darkMode },
  } = useConfiguration();
  return (
    <>
      <main
        className={darkMode ? "bp4-dark" : ""}
        style={{ paddingTop: 36, background: darkMode ? "#111" : "#eee" }}
      >
        <TopBar />
        <AppContent />
      </main>
    </>
  );
};

export default App;
