import React from "react";
import { SaxophoneNoteName, fingerings } from "../../../../GameElements";

interface SaxophoneFingeringProps {
  noteName: SaxophoneNoteName;
  alternative?: number;
}

export const SaxophoneFingeringDisplay = ({
  noteName,
  alternative = 0,
}: SaxophoneFingeringProps) => {
  const displayedButtons: string[] =
    fingerings[noteName][alternative ? alternative : 0];

  const getFill = (id: string) =>
    displayedButtons?.includes(id) ? "#EB5757" : "white";
  // @ts-ignore
  return (
    <div
      style={{
        alignItems: "center",
        aspectRatio: 836 / 1061,
        maxWidth: "80%",
        maxHeight: "100%",
      }}
    >
      <svg width={"100%"} height={"100%"} viewBox="0 0 836 1061" fill="none">
        <g id="fingering">
          <g clipPath="url(#clip0_538416_1266)">
            <rect
              x="0.150391"
              y="0.29834"
              width="835.72"
              height="1059.94"
              rx="18.3998"
              fill="#F7FBFB"
            />
            <path
              id="Vector 9"
              d="M812.202 277.036C824.442 89.1348 488.993 14.2661 319.738 0.319336C208.119 0.319336 88.4985 87.8374 0.5 205.734V778.528C263.169 893.036 797.902 496.565 812.202 277.036Z"
              fill="#EBF5F5"
            />
            <g id="saxophone">
              <path
                id="path3846"
                d="M452.158 560.854C452.178 560.968 452.041 561.107 451.753 561.264C451.466 561.421 451.035 561.592 450.484 561.768C449.934 561.944 449.275 562.121 448.545 562.29C447.815 562.458 447.028 562.614 446.23 562.749C445.432 562.884 444.638 562.995 443.893 563.076C443.148 563.157 442.468 563.207 441.89 563.221C441.312 563.236 440.848 563.216 440.525 563.162C440.203 563.109 440.027 563.022 440.008 562.908C439.988 562.795 440.126 562.655 440.413 562.498C440.7 562.341 441.132 562.17 441.682 561.994C442.233 561.818 442.892 561.641 443.622 561.473C444.352 561.304 445.138 561.148 445.936 561.013C446.735 560.878 447.529 560.767 448.273 560.686C449.018 560.605 449.699 560.556 450.277 560.541C450.855 560.526 451.318 560.546 451.641 560.6C451.964 560.653 452.14 560.74 452.158 560.854V560.854Z"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path3832"
                d="M154.556 -115.392L293.719 970.811L409.94 958.787L217.844 -126.426C199.823 -116.974 180.405 -110.143 154.556 -115.392V-115.392Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4009"
                d="M297.266 151.486C298.534 158.986 296.869 166.666 292.637 172.837C288.404 179.007 281.951 183.163 274.697 184.39C267.443 185.616 259.983 183.814 253.956 179.378C247.93 174.942 243.832 168.237 242.564 160.737C241.296 153.237 242.961 145.557 247.194 139.386C251.426 133.216 257.879 129.06 265.133 127.833C272.387 126.607 279.848 128.41 285.874 132.845C291.9 137.281 295.998 143.987 297.266 151.486V151.486Z"
                fill="#FFEBC2"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path3834"
                d="M292.457 971.263L298.665 1007.98C329.845 1009.08 344.74 1020.26 416.692 996.11L408.951 958.307L292.457 971.263Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path3836"
                d="M301.73 1010.15C293.761 1217.63 685.879 1233.11 637.516 958.093C580.75 997.794 538.538 980.664 493.377 975.049C486.952 1061.25 450.75 1025.7 416.035 996.22L301.73 1010.15Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path3838"
                d="M641.129 959.501L632.944 919.076C582.403 962.38 537.239 952.546 491.778 945.645L492.723 975.156C546.374 994.67 594.983 984.368 641.129 959.501Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path3840"
                d="M632.643 919.292C639.65 785.56 592.41 561.002 661.867 531.829C683.877 536.12 706.515 541.516 718.59 528.306C738.336 488.137 624.534 432.577 620.623 428.204C530.809 375.772 482.005 367.581 451.077 378.674C447.941 380.6 430.793 385.092 471.454 431.495C422.915 617.454 478.629 774.875 491.373 944.06C545.337 954.978 595.572 955.496 632.643 919.292Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path3842"
                d="M471.375 431.032C516.652 478.8 584.155 508.898 661.832 530.908"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path3844"
                d="M437.255 563.41C439.034 573.929 436.25 584.777 429.517 593.567C422.783 602.356 412.65 608.368 401.349 610.279C390.047 612.19 378.501 609.845 369.252 603.758C360.002 597.671 353.806 588.341 352.028 577.822C350.249 567.302 353.032 556.455 359.766 547.665C366.5 538.875 376.632 532.863 387.934 530.952C399.236 529.041 410.782 531.387 420.031 537.474C429.281 543.561 435.476 552.89 437.255 563.41V563.41Z"
                stroke="#FFCC66"
                strokeWidth="11.0399"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path3844_2"
                d="M430.72 563.257C432.247 572.29 429.863 581.634 424.042 589.233C418.217 596.836 409.43 602.061 399.601 603.723C389.773 605.385 379.756 603.34 371.755 598.074C363.759 592.813 358.435 584.772 356.908 575.739C355.38 566.705 357.764 557.361 363.586 549.763C369.41 542.159 378.198 536.934 388.026 535.272C397.854 533.61 407.871 535.656 415.872 540.921C423.868 546.183 429.192 554.223 430.72 563.257Z"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path3844_3"
                d="M440.969 562.249C442.944 573.929 439.859 586.002 432.344 595.812C424.825 605.626 413.489 612.364 400.817 614.507C388.146 616.65 375.225 614.014 364.897 607.218C354.574 600.424 347.691 590.037 345.716 578.357C343.741 566.677 346.826 554.604 354.341 544.794C361.86 534.98 373.196 528.242 385.868 526.1C398.539 523.957 411.46 526.592 421.788 533.389C432.111 540.182 438.994 550.57 440.969 562.249Z"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path4083"
                d="M346.428 312.79C374.536 328.193 380.302 346.875 368.472 368.142L303.862 365.049L304.328 355.958C319.312 352.334 336.273 356.439 349.1 344.381C351.996 333.508 349.344 326.464 340.286 323.842L306.812 331.172L304.032 322.629L346.428 312.79Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4027"
                d="M365.607 368.966L474.325 932.168L481.278 931.414L483.681 942.835L434.619 948.597L441.575 933.904L452.547 934.583L437.517 856.866L440.484 829.753L353.131 405.724L363.182 398.05L359.117 370.063L365.607 368.966Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path3848"
                d="M356.124 575.689C356.301 576.735 355.273 577.939 353.268 579.038C351.263 580.137 348.445 581.041 345.432 581.55C342.42 582.06 339.461 582.133 337.206 581.754C334.951 581.376 333.585 580.576 333.409 579.531C333.232 578.486 334.259 577.281 336.264 576.182C338.269 575.083 341.088 574.179 344.1 573.67C347.112 573.16 350.071 573.087 352.326 573.466C354.581 573.844 355.947 574.644 356.124 575.689V575.689Z"
                stroke="#FFCC66"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path4007"
                d="M310.907 232.149C312.216 239.895 310.444 247.836 305.981 254.224C301.517 260.613 294.726 264.926 287.104 266.215C279.481 267.504 271.65 265.663 265.334 261.098C259.017 256.532 254.733 249.616 253.423 241.87C252.113 234.124 253.885 226.184 258.349 219.795C262.813 213.407 269.603 209.093 277.226 207.804C284.849 206.515 292.68 208.356 298.996 212.922C305.312 217.487 309.597 224.404 310.907 232.149V232.149Z"
                fill="#FFEBC2"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path3884"
                d="M148.117 16.7345C148.315 17.9025 147.967 19.1135 147.15 20.1011C146.334 21.0888 145.115 21.7721 143.763 22.0008C142.41 22.2295 141.035 21.9848 139.939 21.3207C138.843 20.6565 138.116 19.6272 137.919 18.4592C137.721 17.2911 138.069 16.0801 138.886 15.0925C139.702 14.1049 140.921 13.4216 142.273 13.1929C143.626 12.9642 145.001 13.2088 146.097 13.873C147.193 14.5372 147.92 15.5665 148.117 16.7345V16.7345Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path3892"
                d="M127.257 -57.5015L137.475 10.9023L145.453 10.2276L133.157 -58.4993L127.257 -57.5015Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path3896"
                d="M154.241 -17.5593L165.931 -20.2104L171.494 20.6583L159.471 21.3427L154.241 -17.5593Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path3898"
                d="M156.874 72.133L229.059 551.019L235.623 549.957L166.683 76.8304L156.874 72.133Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path3914"
                d="M258.888 852.25L247.605 853.204L234.681 761.195L243.314 760.409L258.888 852.25Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path3912"
                d="M208.028 526.112L242.171 785.552L256.927 785.08L218.925 524.466L208.028 526.112Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path3908"
                d="M222.456 514.846C222.625 515.829 222.542 516.845 222.214 517.836C221.885 518.827 221.316 519.773 220.541 520.62C219.765 521.467 218.797 522.199 217.693 522.773C216.589 523.347 215.369 523.753 214.105 523.967C212.84 524.181 211.555 524.198 210.323 524.019C209.092 523.84 207.937 523.467 206.926 522.922C205.915 522.377 205.067 521.671 204.431 520.843C203.795 520.016 203.383 519.083 203.218 518.099C203.05 517.116 203.132 516.1 203.461 515.109C203.79 514.119 204.358 513.173 205.134 512.326C205.91 511.479 206.878 510.747 207.982 510.173C209.086 509.598 210.306 509.193 211.57 508.979C212.835 508.765 214.12 508.747 215.351 508.926C216.583 509.106 217.738 509.478 218.749 510.023C219.76 510.568 220.608 511.275 221.244 512.102C221.88 512.93 222.292 513.862 222.456 514.846V514.846Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path3910"
                d="M238.808 555.77C239.047 557.183 238.739 558.63 237.952 559.792C237.165 560.953 235.963 561.734 234.61 561.963C233.258 562.192 231.866 561.849 230.741 561.011C229.615 560.173 228.849 558.908 228.61 557.494C228.37 556.08 228.678 554.633 229.466 553.472C230.253 552.31 231.455 551.529 232.807 551.301C234.16 551.072 235.552 551.414 236.677 552.252C237.803 553.09 238.569 554.356 238.808 555.77Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path3916"
                d="M277.412 850.399L256.756 851.869L256.182 840.502L277.152 832.91L277.412 850.399Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path3922"
                d="M595.041 1049.56C597.12 1061.85 594.426 1074.44 587.55 1084.54C580.674 1094.65 570.18 1101.45 558.377 1103.44C546.574 1105.44 534.429 1102.47 524.612 1095.19C514.796 1087.9 508.114 1076.9 506.035 1064.61C503.955 1052.32 506.65 1039.73 513.526 1029.63C520.402 1019.52 530.896 1012.72 542.699 1010.73C554.502 1008.73 566.647 1011.7 576.463 1018.98C586.28 1026.27 592.962 1037.26 595.041 1049.56V1049.56Z"
                fill="#FFEBC2"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path3924"
                d="M353.934 1135.15C391.396 1176.5 428.605 1218.13 537.073 1180.48C632.4 1122.21 585.633 1049.19 597.974 982.307L566.138 985.783L568.516 1011.13C558.217 1054.65 558.099 1108.61 522.103 1125.79L461.265 1155.15C413.732 1150.47 388.178 1142.08 353.934 1135.15V1135.15Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinejoin="round"
              />
              <path
                id="path4081"
                d="M262.956 985.825L246.823 990.914C261.843 1035.16 274.386 1084.49 298.22 1110.62C312.519 1110.01 320.772 1105.11 331.177 1101.74C317.248 1067.75 300.509 1033.72 299.794 999.894L262.956 985.825Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path3926"
                d="M388.076 1098.05C363.096 1063.1 315.592 1055.18 270.441 1044.44L273.7 1035.8C319.265 1040.4 364.207 1046.45 393.302 1089.07L388.076 1098.05Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path3928"
                d="M509.376 1037.74L459.778 1003.65L472.215 993.453L513.29 1028.99L509.376 1037.74Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path3930"
                d="M333.355 1069.54L284.657 1092.61C275.27 1090.3 276.099 1082.56 276.308 1075.14L318.459 1057.22C323.926 1059.68 331.05 1056.72 333.355 1069.54Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4091"
                d="M434.428 953.055C434.738 954.891 442.232 985.25 439.938 985.638C437.643 986.026 415.615 989.751 415.615 989.751L408.128 962.223L434.428 953.055Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path3966"
                d="M440.9 904.935C443.152 918.252 440.001 931.922 432.141 942.937C424.281 953.952 412.355 961.41 398.988 963.67C385.62 965.931 371.905 962.808 360.86 954.99C349.815 947.172 342.345 935.299 340.093 921.982C337.842 908.665 340.992 894.996 348.852 883.981C356.712 872.966 368.638 865.508 382.006 863.247C395.374 860.987 409.088 864.109 420.133 871.927C431.178 879.745 438.648 891.618 440.9 904.935V904.935Z"
                fill="#FFEBC2"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path3966-8"
                d="M433.856 798.996C436.108 812.313 432.957 825.982 425.097 836.997C417.237 848.012 405.311 855.47 391.944 857.731C378.576 859.991 364.861 856.869 353.816 849.051C342.771 841.233 335.301 829.359 333.05 816.043C330.798 802.726 333.948 789.056 341.808 778.041C349.668 767.026 361.594 759.568 374.962 757.308C388.33 755.047 402.044 758.169 413.089 765.987C424.134 773.806 431.604 785.679 433.856 798.996V798.996Z"
                fill="#FFEBC2"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path3997"
                d="M393.311 678.395C395.075 688.828 392.415 699.569 385.917 708.256C379.419 716.942 369.614 722.863 358.66 724.716C347.706 726.568 336.499 724.2 327.506 718.133C318.513 712.066 312.47 702.797 310.706 692.364C308.941 681.932 311.601 671.191 318.099 662.504C324.598 653.817 334.402 647.896 345.356 646.044C356.31 644.192 367.517 646.559 376.51 652.626C385.503 658.693 391.547 667.963 393.311 678.395V678.395Z"
                fill="#FFEBC2"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path3999"
                d="M375.54 597.444C376.303 601.95 376.1 606.574 374.941 611.052C373.782 615.53 371.69 619.775 368.785 623.544C365.88 627.313 362.218 630.533 358.01 633.018C353.801 635.504 349.128 637.207 344.256 638.031C339.385 638.855 334.412 638.783 329.62 637.819C324.828 636.855 320.311 635.019 316.329 632.415C312.346 629.811 308.975 626.489 306.407 622.641C303.84 618.793 302.128 614.493 301.367 609.987C300.604 605.481 300.807 600.857 301.966 596.379C303.126 591.901 305.217 587.656 308.122 583.887C311.027 580.118 314.689 576.898 318.897 574.413C323.106 571.927 327.779 570.224 332.651 569.4C337.522 568.576 342.495 568.648 347.287 569.612C352.079 570.576 356.596 572.412 360.578 575.016C364.561 577.62 367.932 580.942 370.5 584.79C373.067 588.638 374.779 592.938 375.54 597.444V597.444Z"
                fill="#FFEBC2"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path4001"
                d="M358.371 495.917C359.847 504.646 357.942 513.58 353.074 520.752C348.206 527.924 340.774 532.748 332.414 534.162C324.053 535.575 315.449 533.463 308.493 528.291C301.538 523.118 296.801 515.307 295.325 506.578C293.848 497.849 295.754 488.915 300.622 481.743C305.49 474.571 312.922 469.747 321.282 468.333C329.643 466.92 338.247 469.031 345.203 474.204C352.158 479.377 356.895 487.187 358.371 495.917V495.917Z"
                fill="#FFEBC2"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path4003"
                d="M355.359 399.157C356.175 403.968 356.108 408.88 355.16 413.614C354.213 418.347 352.404 422.809 349.837 426.744C347.269 430.678 343.994 434.009 340.198 436.545C336.403 439.082 332.161 440.775 327.715 441.526C323.269 442.278 318.706 442.075 314.287 440.927C309.869 439.78 305.68 437.711 301.962 434.839C298.244 431.967 295.069 428.349 292.617 424.19C290.166 420.031 288.487 415.414 287.677 410.602C286.86 405.791 286.927 400.879 287.875 396.145C288.822 391.412 290.632 386.951 293.199 383.016C295.766 379.081 299.041 375.75 302.837 373.214C306.633 370.677 310.875 368.985 315.321 368.233C319.767 367.481 324.329 367.685 328.748 368.832C333.167 369.98 337.355 372.048 341.073 374.92C344.791 377.792 347.967 381.411 350.418 385.569C352.869 389.728 354.548 394.345 355.359 399.157V399.157Z"
                fill="#FFEBC2"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path4005"
                d="M330.828 321.759C331.531 325.9 331.447 330.133 330.581 334.216C329.715 338.298 328.084 342.151 325.781 345.554C323.478 348.956 320.548 351.841 317.159 354.045C313.77 356.249 309.988 357.728 306.03 358.397C302.071 359.067 298.013 358.914 294.088 357.947C290.163 356.98 286.448 355.218 283.154 352.762C279.86 350.306 277.053 347.204 274.893 343.633C272.732 340.062 271.261 336.092 270.563 331.951C269.861 327.81 269.945 323.577 270.811 319.494C271.677 315.412 273.308 311.559 275.611 308.156C277.914 304.754 280.844 301.869 284.233 299.665C287.622 297.461 291.403 295.982 295.362 295.313C299.321 294.643 303.378 294.796 307.304 295.763C311.229 296.73 314.944 298.492 318.238 300.948C321.531 303.404 324.339 306.506 326.499 310.077C328.659 313.648 330.131 317.618 330.828 321.759V321.759Z"
                fill="#FFEBC2"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path4011"
                d="M275.682 88.3607C276.446 92.8815 275.487 97.5033 273.015 101.209C270.544 104.915 266.762 107.402 262.502 108.122C258.242 108.843 253.853 107.738 250.3 105.051C246.747 102.363 244.322 98.3138 243.557 93.7931C242.793 89.2723 243.752 84.6506 246.224 80.9445C248.695 77.2385 252.477 74.7518 256.737 74.0314C260.997 73.311 265.386 74.416 268.939 77.1033C272.492 79.7906 274.917 83.84 275.682 88.3607V88.3607Z"
                fill="#FFEBC2"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path4013"
                d="M242.721 -29.8861C243.647 -24.4091 242.215 -18.7639 238.739 -14.1924C235.262 -9.62097 230.027 -6.49774 224.185 -5.50981C218.343 -4.52188 212.372 -5.75015 207.586 -8.92444C202.8 -12.0987 199.591 -16.959 198.665 -22.4361C197.739 -27.9132 199.171 -33.5584 202.648 -38.1298C206.124 -42.7013 211.359 -45.8245 217.201 -46.8124C223.043 -47.8003 229.014 -46.5721 233.8 -43.3978C238.586 -40.2235 241.795 -35.3632 242.721 -29.8861Z"
                fill="#FFEBC2"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path4029"
                d="M602.191 903.913C605.155 921.44 601.325 939.377 591.544 953.779C581.763 968.18 566.832 977.867 550.035 980.707C533.239 983.547 515.953 979.309 501.981 968.924C488.008 958.539 478.493 942.859 475.53 925.332C472.566 907.806 476.396 889.868 486.177 875.467C495.958 861.065 510.889 851.379 527.685 848.539C544.482 845.698 561.767 849.937 575.74 860.321C589.712 870.706 599.227 886.387 602.191 903.913Z"
                fill="#FFEBC2"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path4031"
                d="M587.659 745.4C590.808 764.022 586.624 783.1 576.027 798.436C565.431 813.773 549.289 824.111 531.154 827.178C513.019 830.245 494.376 825.788 479.325 814.789C464.275 803.79 454.051 787.149 450.902 768.527C447.753 749.904 451.937 730.827 462.534 715.491C473.13 700.154 489.272 689.816 507.407 686.749C525.542 683.682 544.186 688.139 559.236 699.138C574.286 710.137 584.51 726.778 587.659 745.4V745.4Z"
                fill="#FFEBC2"
                stroke="#333366"
                strokeWidth="3.67996"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="path4033"
                d="M169.353 -89.7059L270.017 557.687L290.346 551.579L182.273 -94.2511L169.353 -89.7059Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4035"
                d="M166.271 -66.0583L265.787 599.788L250.75 601.663L154.044 -68.9102L166.271 -66.0583Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4039"
                d="M191.006 49.0135L184.352 49.1375L182.047 35.5083L188.916 34.6805L191.006 49.0135Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4041"
                d="M242.669 22.9162C242.669 22.9162 224.675 32.9684 224.895 34.2664C225.114 35.5644 225.966 52.4434 227.698 52.818C229.43 53.1927 248.426 54.9873 248.426 54.9873L242.669 22.9162Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4043"
                d="M241.856 91.1475C240.233 91.4219 194.748 98.7798 194.748 98.7798L196.709 104.456L243.493 96.8788L241.856 91.1475Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4045"
                d="M240.839 164.081C238.567 164.465 208.118 169.948 208.118 169.948C208.118 169.948 204.256 160.921 206.203 160.592C208.15 160.263 240.225 156.508 240.225 156.508L240.839 164.081Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4047"
                d="M262.066 261.966L221.498 266.823L220.561 259.304L257.504 254.726L262.066 261.966Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4049"
                d="M319.963 286.556L232.256 308.73L231.099 299.914L320.488 279.791L319.963 286.556Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4051"
                d="M270.85 339.584C265.982 340.407 237.266 346.265 237.266 346.265L236.753 335.337L268.554 329.959L270.85 339.584Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4053"
                d="M288.304 421.078C285.599 420.868 243.692 431.626 243.692 431.626C243.692 431.626 238.966 423.413 241.237 423.029C243.509 422.645 286.608 413.02 286.608 413.02L288.304 421.078Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4055"
                d="M269.038 350.575L275.294 387.569L305.01 369.86L303.588 363.424L269.038 350.575Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4057"
                d="M362.048 442.653L279.569 456.267L279.765 447.555L360.357 436.597L362.048 442.653Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4059"
                d="M277.15 463.686L361.727 444.711L365.554 461.42L278.143 471.529L277.15 463.686Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4061"
                d="M296.483 516.826L258.836 521.19L257.249 513.781L294.028 508.229L296.483 516.826Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4063"
                d="M265.816 578.439L337.7 557.253L336.973 547.029L263.709 567.763L265.816 578.439Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4079"
                d="M277.551 1074.93L247.786 903.905L288.874 890.281L294.004 908.773L265.667 914.9L291.677 1068.71L278.497 1077.62L277.551 1074.93Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4087"
                d="M431.503 687.316C432.357 692.364 453.018 725.217 453.018 725.217L453.362 741.209L432.522 729.627L431.503 687.316Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4089"
                d="M473.535 1019.61C472.992 1016.39 459.89 944.498 459.89 944.498L451.481 947.808L461.37 1020.25L473.535 1019.61Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4095"
                d="M257.398 712.022C239.643 705.967 242.121 693.113 239.31 682.037C237.74 665.212 239.539 651.863 249.778 647.222L257.398 712.022Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path3894"
                d="M138.484 -70.8634L158.646 88.244L171.536 84.7156L143.507 -73.0615L138.484 -70.8634Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4069"
                d="M271.832 717.668C272.129 713.841 249.788 618.019 249.788 618.019L268.681 615.296L282.471 719.173L271.832 717.668Z"
                fill="#FFCC66"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <g id="buttons">
                <path
                  id="leftPalmMiddle"
                  fill={getFill("leftPalmMiddle")}
                  d="M349.028 65.8287C350.065 68.8698 350.784 71.986 351.145 74.9992C351.507 78.0123 351.503 80.8634 351.135 83.3895C350.766 85.9156 350.04 88.0671 348.998 89.7212C347.955 91.3754 346.617 92.4996 345.06 93.0297C343.503 93.5599 341.757 93.4854 339.921 92.8108C338.086 92.1361 336.198 90.8745 334.365 89.0979C332.532 87.3212 330.79 85.0645 329.238 82.4566C327.686 79.8487 326.355 76.9407 325.32 73.8989C324.284 70.8577 323.564 67.7415 323.203 64.7284C322.842 61.7152 322.845 58.8642 323.214 56.3381C323.582 53.812 324.308 51.6604 325.351 50.0063C326.393 48.3522 327.731 47.2279 329.289 46.6978C330.846 46.1677 332.592 46.2421 334.427 46.9168C336.262 47.5914 338.15 48.8531 339.983 50.6297C341.816 52.4063 343.559 54.6631 345.111 57.271C346.663 59.8789 347.994 62.7868 349.028 65.8287Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="leftPalmBottom"
                  fill={getFill("leftPalmBottom")}
                  d="M389.389 106.189C390.425 109.23 391.144 112.346 391.506 115.36C391.867 118.373 391.864 121.224 391.495 123.75C391.127 126.276 390.4 128.427 389.358 130.082C388.316 131.736 386.978 132.86 385.42 133.39C383.863 133.92 382.117 133.846 380.282 133.171C378.447 132.497 376.559 131.235 374.726 129.458C372.892 127.682 371.15 125.425 369.598 122.817C368.046 120.209 366.715 117.301 365.681 114.259C364.644 111.218 363.925 108.102 363.563 105.089C363.202 102.076 363.206 99.2245 363.574 96.6984C363.943 94.1723 364.669 92.0208 365.711 90.3667C366.753 88.7126 368.092 87.5883 369.649 87.0582C371.206 86.528 372.952 86.6025 374.787 87.2771C376.622 87.9518 378.511 89.2134 380.344 90.99C382.177 92.7667 383.919 95.0234 385.471 97.6313C387.023 100.239 388.354 103.147 389.389 106.189Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="leftPinkyTop"
                  fill={getFill("leftPinkyTop")}
                  d="M410.214 299.294C405.805 300.258 401.369 300.897 397.16 301.173C392.95 301.449 389.051 301.357 385.683 300.903C382.316 300.448 379.547 299.64 377.534 298.524C375.521 297.409 374.305 296.007 373.953 294.4C373.602 292.793 374.124 291.011 375.487 289.158C376.851 287.304 379.031 285.414 381.902 283.597C384.773 281.779 388.279 280.069 392.219 278.564C396.16 277.06 400.458 275.79 404.868 274.827C409.278 273.863 413.714 273.224 417.923 272.948C422.132 272.672 426.032 272.763 429.399 273.218C432.767 273.672 435.536 274.481 437.549 275.596C439.561 276.712 440.778 278.114 441.129 279.721C441.48 281.328 440.959 283.11 439.595 284.963C438.232 286.817 436.052 288.706 433.181 290.524C430.31 292.342 426.804 294.052 422.863 295.557C418.923 297.061 414.624 298.331 410.214 299.294Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="leftPalmTop"
                  fill={getFill("leftPalmTop")}
                  d="M333.893 108.713C334.93 111.755 335.649 114.871 336.011 117.884C336.372 120.897 336.369 123.748 336 126.274C335.631 128.8 334.905 130.952 333.863 132.606C332.821 134.26 331.483 135.384 329.925 135.915C328.368 136.445 326.622 136.37 324.787 135.696C322.952 135.021 321.064 133.759 319.23 131.983C317.397 130.206 315.655 127.949 314.103 125.341C312.551 122.733 311.22 119.825 310.186 116.784C309.149 113.743 308.43 110.626 308.068 107.613C307.707 104.6 307.71 101.749 308.079 99.2228C308.448 96.6968 309.174 94.5452 310.216 92.8911C311.258 91.237 312.596 90.1127 314.154 89.5826C315.711 89.0525 317.457 89.1269 319.292 89.8015C321.127 90.4762 323.015 91.7378 324.849 93.5145C326.682 95.2911 328.424 97.5478 329.976 100.156C331.528 102.764 332.859 105.672 333.893 108.713Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="B"
                  fill={getFill("B")}
                  d="M317.942 165.606C318.57 170.921 317.061 176.268 313.747 180.471C310.433 184.673 305.585 187.387 300.27 188.015C294.955 188.643 289.608 187.134 285.405 183.82C281.202 180.506 278.489 175.658 277.861 170.343C277.232 165.028 278.741 159.681 282.056 155.478C285.37 151.276 290.218 148.562 295.533 147.934C300.848 147.306 306.195 148.815 310.398 152.129C314.6 155.443 317.314 160.291 317.942 165.606V165.606Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Bb"
                  fill={getFill("Bb")}
                  d="M296.657 204.307C296.961 206.103 296.909 207.941 296.502 209.717C296.096 211.493 295.343 213.171 294.288 214.656C293.232 216.142 291.895 217.404 290.351 218.373C288.807 219.341 287.088 219.996 285.291 220.299C283.494 220.603 281.655 220.55 279.878 220.144C278.102 219.737 276.423 218.984 274.938 217.929C273.453 216.873 272.19 215.535 271.222 213.992C270.255 212.448 269.601 210.729 269.298 208.933C268.993 207.137 269.046 205.299 269.452 203.523C269.859 201.747 270.611 200.068 271.667 198.583C272.722 197.098 274.06 195.835 275.604 194.867C277.148 193.899 278.867 193.244 280.664 192.94C282.461 192.636 284.3 192.689 286.076 193.096C287.853 193.503 289.531 194.256 291.017 195.311C292.502 196.367 293.764 197.704 294.732 199.248C295.7 200.791 296.354 202.51 296.657 204.307Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="A"
                  fill={getFill("A")}
                  d="M330.324 238.72C331.217 243.997 329.976 249.413 326.876 253.775C323.775 258.138 319.068 261.09 313.791 261.982C308.514 262.875 303.098 261.634 298.736 258.534C294.373 255.433 291.421 250.727 290.529 245.449C289.636 240.172 290.877 234.757 293.977 230.394C297.078 226.032 301.785 223.079 307.062 222.187C312.339 221.295 317.755 222.535 322.117 225.636C326.48 228.736 329.432 233.443 330.324 238.72V238.72Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="leftPinkyBottom"
                  fill={getFill("leftPinkyBottom")}
                  d="M393.927 350.043C395.983 349.335 463.472 329.939 463.472 329.939C467.952 336.399 477.156 338.145 468.879 357.338L400.144 378.123C390.427 371.394 391.483 360.957 393.927 350.043Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                  strokeLinejoin="round"
                />
                <path
                  id="leftPinkyRight"
                  fill={getFill("leftPinkyRight")}
                  d="M378.976 315.059C379.777 314.731 406.978 307.54 406.978 307.54C410.625 314.224 415.378 317.301 416.038 333.725L388.58 342.002C382.401 334.237 380.418 324.758 378.976 315.059Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                  strokeLinejoin="round"
                />
                <path
                  id="leftPinkyLeft"
                  fill={getFill("leftPinkyLeft")}
                  d="M418.925 303.915C419.726 303.588 446.927 296.396 446.927 296.396C450.574 303.08 455.327 306.158 455.988 322.582L428.529 330.858C422.35 323.093 420.367 313.615 418.925 303.915Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                  strokeLinejoin="round"
                />
                <path
                  id="D"
                  fill={getFill("D")}
                  d="M386.236 887.7C387.466 894.972 385.757 902.435 381.484 908.446C377.212 914.458 370.726 918.526 363.454 919.756C356.182 920.985 348.719 919.276 342.708 915.003C336.696 910.731 332.628 904.245 331.398 896.973C330.169 889.701 331.878 882.239 336.151 876.227C340.423 870.215 346.909 866.147 354.181 864.918C361.453 863.688 368.915 865.397 374.927 869.67C380.939 873.942 385.007 880.428 386.236 887.7V887.7Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="E"
                  fill={getFill("E")}
                  d="M370.663 807.612C371.893 814.884 370.184 822.347 365.911 828.358C361.639 834.37 355.153 838.438 347.881 839.668C340.609 840.897 333.146 839.188 327.135 834.916C321.123 830.643 317.055 824.157 315.825 816.885C314.595 809.613 316.305 802.151 320.577 796.139C324.85 790.128 331.336 786.059 338.608 784.83C345.88 783.6 353.342 785.309 359.354 789.582C365.365 793.854 369.434 800.34 370.663 807.612Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="path4085"
                  fill={getFill("path4085")}
                  d="M371.397 734.808C376.589 733.93 421.829 736.961 421.829 736.961L421.022 716.403L372.227 719.981L371.397 734.808Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                />
                <path
                  id="rightPalmBottom"
                  fill={getFill("rightPalmBottom")}
                  d="M200.96 815.717C200.633 813.377 195.117 756.88 195.117 756.88C202.289 749.562 210.8 745.265 225.336 754.562L230.234 816.873C221.129 821.16 211.46 821.407 200.96 815.717Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                />
                <path
                  id="rightPalmMiddle"
                  fill={getFill("rightPalmMiddle")}
                  d="M193.633 741.43C193.371 739.557 188.67 678.651 188.67 678.651C196.222 675.721 200.037 668.047 217.681 677.934L223.514 740.114C214.527 747.513 204.168 745.104 193.633 741.43Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                />
                <path
                  id="rightPalmTop"
                  fill={getFill("rightPalmTop")}
                  d="M186.696 663.953C179.775 601.649 174.866 610.41 169.44 600.899C174.395 596.308 179.284 591.671 197.859 596.587C213.535 607.186 212.038 637.813 215.531 662.62C208.356 669.586 199.596 672.308 186.696 663.953Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                />
                <path
                  id="rightPinkyTop"
                  fill={getFill("rightPinkyTop")}
                  d="M244.041 918.779C247.194 882.969 319.076 868.649 320.547 931.597L316.781 943.941L252.418 930.24L244.041 918.779Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                />
                <path
                  id="rightPinkyBottom"
                  fill={getFill("rightPinkyBottom")}
                  d="M244.254 953.354L256.642 943.395L318.249 950.361L319.941 984.843C290.172 993.416 256.806 1017.1 244.254 953.354Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                />
                <path
                  id="F"
                  fill={getFill("F")}
                  d="M361.767 711.949C362.996 719.221 361.287 726.684 357.015 732.695C352.742 738.707 346.256 742.775 338.984 744.005C331.712 745.234 324.25 743.525 318.238 739.252C312.226 734.98 308.158 728.494 306.929 721.222C305.699 713.95 307.408 706.488 311.681 700.476C315.953 694.464 322.439 690.396 329.711 689.167C336.983 687.937 344.446 689.646 350.457 693.919C356.469 698.191 360.537 704.677 361.767 711.949Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="octave"
                  fill={getFill("octave")}
                  d="M189.787 206.363C177.536 221.339 166.397 237.221 168.499 263.91C174.963 280.045 182.115 295.312 203.11 293.123L189.787 206.363Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                />
                <path
                  id="G"
                  fill={getFill("G")}
                  d="M360.595 276.56C361.038 279.172 360.962 281.846 360.371 284.429C359.78 287.012 358.686 289.453 357.151 291.614C355.615 293.774 353.669 295.611 351.423 297.019C349.177 298.428 346.676 299.38 344.062 299.822C341.448 300.264 338.772 300.187 336.188 299.595C333.604 299.004 331.162 297.909 329.002 296.374C326.841 294.838 325.005 292.892 323.598 290.647C322.19 288.402 321.239 285.902 320.799 283.289C320.356 280.677 320.432 278.003 321.023 275.42C321.614 272.837 322.708 270.396 324.243 268.236C325.779 266.075 327.725 264.238 329.971 262.83C332.217 261.421 334.718 260.469 337.332 260.027C339.946 259.585 342.622 259.662 345.206 260.254C347.79 260.845 350.232 261.94 352.392 263.476C354.553 265.011 356.389 266.957 357.796 269.202C359.204 271.447 360.155 273.947 360.595 276.56Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="sideF"
                  fill={getFill("sideF")}
                  d="M325.227 854.035C325.227 858.093 322.068 862.208 315.97 865.417C309.958 868.582 301.524 870.595 292.107 870.595C282.69 870.595 274.257 868.582 268.244 865.417C262.146 862.208 258.987 858.093 258.987 854.035C258.987 849.977 262.146 845.862 268.244 842.653C274.257 839.488 282.69 837.475 292.107 837.475C301.524 837.475 309.958 839.488 315.97 842.653C322.068 845.862 325.227 849.977 325.227 854.035Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                />
                <rect
                  id="highF"
                  fill={getFill("highF")}
                  x="272.053"
                  y="732.234"
                  width="29.4397"
                  height="73.5992"
                  rx="12.8799"
                  transform="rotate(-6.1029 272.053 732.234)"
                  stroke="#333366"
                  strokeWidth="3.67996"
                />
                <path
                  id="frontF"
                  fill={getFill("frontF")}
                  d="M259.935 146.466C262.516 146.355 265.182 145.899 267.782 145.126C270.381 144.354 272.864 143.279 275.087 141.963C277.31 140.646 282.001 136 282 127.5C281.999 119 276.5 113.5 274.587 107.235C274.263 106.174 270.433 106.488 269.848 109C269.25 111.565 271.144 117.118 269.848 117.735C267.282 118.955 263.247 120.348 260.647 121.119C258.047 121.891 255.565 122.966 253.342 124.282C251.119 125.599 249.199 127.132 247.691 128.793C246.184 130.454 245.119 132.211 244.556 133.964C243.994 135.716 243.946 137.43 244.414 139.007C244.883 140.584 245.859 141.993 247.288 143.154C248.716 144.315 250.568 145.205 252.738 145.773C254.908 146.342 257.353 146.577 259.935 146.466Z"
                  stroke="#333366"
                  strokeWidth="3.67996"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <path
                id="path4075"
                d="M265.272 945.926C265.491 947.224 264.207 953.45 264.207 953.45L300.63 957.638L301.964 949.735L265.272 945.926Z"
                fill="#333366"
                stroke="#333366"
                strokeWidth="3.67996"
              />
              <path
                id="path4077"
                d="M261.31 931.86L265.447 922.055L308.346 933.426L304.005 942.024L261.31 931.86Z"
                stroke="#333366"
                strokeWidth="3.67996"
              />
            </g>
          </g>
          <rect
            x="2.91036"
            y="3.05831"
            width="830.2"
            height="1054.42"
            rx="15.6398"
            stroke="#99CCCC"
            strokeWidth="5.51994"
          />
        </g>
        <defs>
          <clipPath id="clip0_538416_1266">
            <rect
              x="0.150391"
              y="0.29834"
              width="835.72"
              height="1059.94"
              rx="18.3998"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
