import React from "react";
import { Button } from "@blueprintjs/core";
import { useConfiguration } from "../../../contexts/ConfigurationContext";
import { useOpenContext } from "../../../contexts/OpenContext";
import { QuizElementEditor } from "../QuizElementEditor";
import { QuizEditorProps } from "./QuizEditorProps";
import TitleEditor from "./TitleEditor";
import { TopBar } from "./TopBar";

function QuizEditor({ quiz, index, count, lesson }: QuizEditorProps) {
  const {
    configuration: { deviceWidth, deviceHeight, darkMode },
  } = useConfiguration();
  const { isOpen, toggle } = useOpenContext();
  const border = isOpen(quiz.id)
    ? "solid red"
    : darkMode
    ? "solid #eeeeee"
    : "solid #949494";
  return (
    <div
      onClick={isOpen(quiz.id) ? () => toggle(quiz.id) : undefined}
      style={{
        width: deviceWidth,
        maxWidth: deviceWidth,
        minWidth: deviceWidth,
        height: deviceHeight,
        maxHeight: deviceHeight,
        minHeight: deviceHeight,
        border,
        background: darkMode ? "#949494" : "#eeeeee",
        display: "grid",
        gridTemplateRows: "min-content 1fr min-content",
        overflow: "scroll",
      }}
    >
      <TopBar quiz={quiz} index={index} count={count} lesson={lesson} />
      <div
        style={{
          padding: 12,
        }}
      >
        <TitleEditor quiz={quiz} />
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "space-evenly",
            flexDirection: "column",
          }}
        >
          {quiz.elements.map((element, index) => (
            <QuizElementEditor
              quiz={quiz}
              element={element}
              index={index}
              key={element.id}
            />
          ))}
        </div>
      </div>
      <div
        style={{
          padding: 8,
          width: "100%",
        }}
      >
        <Button fill>Next</Button>
      </div>
    </div>
  );
}

export default QuizEditor;
