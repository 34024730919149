import { cloneDeepWith } from "lodash";
import {
  Clef,
  Era,
  Lesson,
  Level,
  NoteDuration,
  Quiz,
  QuizElement,
  QuizElementType,
  SaxophoneNoteName,
} from "../GameElements";

export const duplicate = <T extends {}>(
  element: T,
  customizer = (v: T) => v,
): T => {
  return customizer(
    cloneDeepWith(element, (value, key) => {
      if (key === "id") return crypto.randomUUID();
    }),
  );
};
export const newEra = (name: string, index: number): Era => ({
  name: name || "New Era",
  id: crypto.randomUUID(),
  levels: [newLevel()],
  index,
});
export const newLevel = (name?: string): Level => ({
  name: name || "New level",
  id: crypto.randomUUID(),
  lessons: [newLesson()],
});
export const newLesson = (name?: string): Lesson => ({
  name: name || "New lesson",
  id: crypto.randomUUID(),
  quizes: [newQuiz()],
});
export const newQuiz = (name?: string): Quiz => ({
  id: crypto.randomUUID(),
  title: name ?? "Quiz title",
  elements: [],
});

export const newElement = (elementType: QuizElementType): QuizElement => {
  const id = crypto.randomUUID();
  switch (elementType) {
    case QuizElementType.body:
      return { id, type: QuizElementType.body, text: "" };
    case QuizElementType.metronome:
      return { id, type: QuizElementType.metronome, bpm: 60, beats: 4 };
    case QuizElementType.remoteImage:
      return { id, type: QuizElementType.remoteImage, src: "" };
    case QuizElementType.remoteSound:
      return { id, type: QuizElementType.remoteSound, src: "" };
    case QuizElementType.sheetMusic:
      return {
        id,
        type: QuizElementType.sheetMusic,
        content: {
          clef: Clef.G,
          measures: [
            {
              id: crypto.randomUUID(),
              notes: [
                {
                  duration: NoteDuration.whole,
                  noteName: "C4",
                },
              ],
            },
          ],
        },
      };
    case QuizElementType.saxophoneFingering:
      return {
        id,
        type: QuizElementType.saxophoneFingering,
        noteName: "C4" as SaxophoneNoteName,
        alternative: 0,
      };
    case QuizElementType.textAnswers:
      return {
        id,
        type: QuizElementType.textAnswers,
        answers: [],
      };
    case QuizElementType.remoteImageAnswers:
      return {
        id,
        type: QuizElementType.remoteImageAnswers,
        answers: [],
      };
    case QuizElementType.sheetMusicAnswers:
      return {
        id,
        type: QuizElementType.sheetMusicAnswers,
        answers: [],
      };
    case QuizElementType.saxophoneFingeringAnswers:
      return {
        id,
        type: QuizElementType.saxophoneFingeringAnswers,
        answers: [],
      };
  }
};
