import React from "react";
import { EditableText } from "@blueprintjs/core";
import { useEras } from "../../../../contexts/ErasContext";
import { QuizBodyElement } from "../../../../GameElements";
import { ElementEditorProps } from "./ElementEditorProps";
import {
  TopRightActionsButton,
  useElementActions,
} from "./TopRightActionsButton";

function BodyElementEditor(props: ElementEditorProps<QuizBodyElement>) {
  const { update } = useEras();
  const actions = useElementActions(props);
  return (
    <TopRightActionsButton {...{ actions }}>
      <EditableText
        multiline
        confirmOnEnterKey
        placeholder={"Body..."}
        onConfirm={(t) => {
          props.element.text = t;
          update();
        }}
        defaultValue={props.element.text}
      />
    </TopRightActionsButton>
  );
}

export default BodyElementEditor;
