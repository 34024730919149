import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Collapse,
  Dialog,
  DialogFooter,
  H4,
  Icon,
  IconName,
  InputGroup,
  Intent,
  Label,
  Menu,
  MenuItem,
} from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { Select2 } from "@blueprintjs/select";
import { useConfirmDialog } from "../../../../../contexts/ConfirmDialogContext";
import useLocalState from "../../../../../hooks/useLocalState";
import { getXmlFromSheetMeasures } from "../../../../../GameElements/utils/getXmlFromSheetMeasures";
import { duplicate } from "../../../../../utils";
import { flatten, isNil } from "lodash";
import {
  Clef,
  Dot,
  Fifth,
  NoteDuration,
  NoteName,
  SheetMusicContent,
  SheetNote,
  Slur,
  Tie,
  bassNoteNames,
  clefs,
  fifths,
  noteDurations,
  saxophoneNoteNames,
} from "../../../../../GameElements";
import MusicSheetDisplay from "./MusicSheetDisplay";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "@hello-pangea/dnd";

type SheetMusicEditorPopupProps = {
  content: SheetMusicContent;
  setContent: (newElement: SheetMusicContent) => void;
  close: () => void;
};
const noteDurationHalves: { [key in NoteDuration]?: NoteDuration } = {
  [NoteDuration.whole]: NoteDuration.half,
  [NoteDuration.half]: NoteDuration.quarter,
  [NoteDuration.quarter]: NoteDuration.eighth,
  [NoteDuration.eighth]: NoteDuration.sixteenth,
} as const;
const noteDurationDoubles: { [key in NoteDuration]?: NoteDuration } = {
  [NoteDuration.half]: NoteDuration.whole,
  [NoteDuration.quarter]: NoteDuration.half,
  [NoteDuration.eighth]: NoteDuration.quarter,
  [NoteDuration.sixteenth]: NoteDuration.eighth,
} as const;
const doubleDuration = (duration: NoteDuration) =>
  noteDurationDoubles[duration] ?? duration;

const halfDuration = (duration: NoteDuration) =>
  noteDurationHalves[duration] ?? duration;

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

type NoteBaseName = "A" | "B" | "C" | "D" | "E" | "F" | "G";
const displayFifth = (fifths: Fifth) =>
  fifths === 0 ? "0 #" : fifths > 0 ? `${fifths} #` : `${-fifths} b`;

const sharps: {
  [key in Fifth]: { notes: NoteBaseName[]; alteration: "sharp" | "flat" };
} = {
  0: {
    notes: [],
    alteration: "sharp",
  },
  7: {
    notes: ["F", "C", "G", "D", "A", "E", "B"],
    alteration: "sharp",
  },
  "-7": {
    notes: ["B", "E", "A", "D", "G", "D", "F"],
    alteration: "flat",
  },
  6: {
    notes: ["F", "C", "G", "D", "A", "E"],
    alteration: "sharp",
  },
  "-6": {
    notes: ["B", "E", "A", "D", "G", "D"],
    alteration: "flat",
  },
  5: {
    notes: ["F", "C", "G", "D", "A"],
    alteration: "sharp",
  },
  "-5": {
    notes: ["B", "E", "A", "D", "G"],
    alteration: "flat",
  },
  4: {
    notes: ["F", "C", "G", "D"],
    alteration: "sharp",
  },
  "-4": {
    notes: ["B", "E", "A", "D"],
    alteration: "flat",
  },
  3: {
    notes: ["F", "C", "G"],
    alteration: "sharp",
  },
  "-3": {
    notes: ["B", "E", "A"],
    alteration: "flat",
  },
  2: {
    notes: ["F", "C"],
    alteration: "sharp",
  },
  "-2": {
    notes: ["B", "E"],
    alteration: "flat",
  },
  1: {
    notes: ["F"],
    alteration: "sharp",
  },
  "-1": {
    notes: ["B"],
    alteration: "flat",
  },
};

const reorder = <T,>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const alternateNote = (
  noteName: NoteName | undefined,
  alteration: "sharp" | "flat" | "natural",
): NoteName | undefined => {
  if (!noteName) return noteName;
  return noteName.replace(
    /(?<=\d)[#b]?$/,
    alteration === "sharp" ? "#" : alteration === "flat" ? "b" : "",
  ) as NoteName;
};

function getSlurOrTieIcon(note: SheetNote): IconName {
  if (note.slur === Slur.startTop) {
    return "chevron-up";
  }
  if (note.slur === Slur.startBottom) {
    return "chevron-down";
  } else if (note.slur === Slur.stop) {
    return "chevron-right";
  } else if (note.tie === Tie.startTop) {
    return "caret-up";
  } else if (note.tie === Tie.startBottom) {
    return "caret-down";
  } else if (note.tie === Tie.stop) {
    return "caret-right";
  }
  return "blank";
}

function updateSlurOrTie(note: SheetNote) {
  if (note.slur === Slur.startTop) {
    note.tie = undefined;
    note.slur = Slur.startBottom;
  } else if (note.slur === Slur.startBottom) {
    note.tie = undefined;
    note.slur = Slur.stop;
  } else if (note.slur === Slur.stop) {
    note.slur = undefined;
    note.tie = undefined;
  } else if (note.tie === Tie.startTop) {
    note.slur = undefined;
    note.tie = Tie.startBottom;
  } else if (note.tie === Tie.startBottom) {
    note.slur = undefined;
    note.tie = Tie.stop;
  } else if (note.tie === Tie.stop) {
    note.slur = Slur.startTop;
    note.tie = undefined;
  } else {
    note.slur = undefined;
    note.tie = Tie.startTop;
  }
}

function updateDot(note: SheetNote) {
  if (note.dot === undefined) {
    note.dot = Dot.single;
  } else if (note.dot === Dot.single) {
    note.dot = Dot.double;
  } else {
    note.dot = undefined;
  }
}

function getDotIcon(note: SheetNote): IconName {
  if (note.dot === undefined) {
    return "blank";
  } else if (note.dot === Dot.single) {
    return "symbol-circle";
  } else {
    return "ungroup-objects";
  }
}

function SheetMusicEditorDialog({
  close,
  setContent,
  content,
}: SheetMusicEditorPopupProps) {
  const [orientation, setOrientation] = useLocalState<"row" | "column">(
    "SheetMusicEditorDialog.orientation",
    "row",
  );
  const [replaceAllOcurences, setReplaceAllOcurences] = useState(false);
  const [sourceReplaceNote, setSourceReplaceNote] = useState<NoteName>();
  const [targetReplaceNote, setTargetReplaceNote] = useState<NoteName>();
  const [openMeasures, setOpenMeasures] = useState<number[]>([]);

  const removeAlterations = () => {
    const sharpsMap = sharps[content.key?.fifths || 0];
    content.measures = content.measures.map((measure) => ({
      ...measure,
      notes: measure.notes.map((note) => {
        if (
          sharpsMap.notes.some((noteName) => note.noteName?.includes(noteName))
        ) {
          console.log({ altered: true, sharpsMap, noteName: note.noteName });

          return {
            ...note,
            noteName: alternateNote(note.noteName, sharpsMap.alteration),
          };
        }
        return {
          ...note,
          noteName: alternateNote(note.noteName, "natural"),
        };
      }),
    }));
    setContent({ ...content });
  };
  const transposeAllUp = (e: React.MouseEvent) => {
    const interval = e.metaKey || e.ctrlKey || e.shiftKey || e.altKey ? 1 : 3;
    content.measures = content.measures.map((measure) => ({
      ...measure,
      notes: measure.notes.map((note) => ({
        ...note,
        noteName: usedNotes[usedNotes.indexOf(note.noteName!) + interval],
      })),
    }));
    setContent({ ...content });
  };
  const transposeAllDown = (e: React.MouseEvent) => {
    console.log({ e });
    const interval = e.metaKey || e.ctrlKey || e.shiftKey || e.altKey ? 1 : 3;
    content.measures = content.measures.map((measure) => ({
      ...measure,
      notes: measure.notes.map((note) => ({
        ...note,
        noteName: usedNotes[usedNotes.indexOf(note.noteName!) - interval],
      })),
    }));
    setContent({ ...content });
  };
  const replaceAll = () => {
    if (!sourceReplaceNote || !targetReplaceNote) return;
    content.measures = content.measures.map((measure) => ({
      ...measure,
      notes: measure.notes.map((note) => ({
        ...note,
        noteName:
          note.noteName === sourceReplaceNote
            ? targetReplaceNote
            : note.noteName,
      })),
    }));
    setContent({ ...content });
  };
  const fasterX2 = () => {
    content.measures = content.measures.map((measure) => ({
      ...measure,
      notes: measure.notes.map((note) => ({
        ...note,
        duration: halfDuration(note.duration),
      })),
    }));
    setContent({ ...content });
  };
  const slowerX2 = () => {
    content.measures = content.measures.map((measure) => ({
      ...measure,
      notes: measure.notes.map((note) => ({
        ...note,
        duration: doubleDuration(note.duration),
      })),
    }));
    setContent({ ...content });
  };
  const { openConfirmDialog } = useConfirmDialog();
  const mergePairs = () => {
    content.measures = content.measures
      .map((measure, index) => {
        if (index % 2 === 1) return undefined;
        const nextMeasure = content.measures[index + 1];
        return {
          ...measure,
          notes: [...measure.notes, ...(nextMeasure?.notes ?? [])],
        };
      })
      .filter(notEmpty);
    setContent({ ...content });
  };
  const splitPairs = () => {
    content.measures = flatten(
      content.measures.map((measure) => {
        const firstHalfNotes = measure.notes.filter(
          (n, index) => index < measure.notes.length / 2,
        );
        const secondHalfNotes = measure.notes.filter(
          (n, index) => index >= measure.notes.length / 2,
        );
        return [
          {
            ...measure,
            notes: firstHalfNotes,
          },
          { id: crypto.randomUUID(), notes: secondHalfNotes },
        ];
      }),
    );
    setContent({ ...content });
  };
  const usedNotes =
    content.clef === Clef.G ? [...saxophoneNoteNames] : [...bassNoteNames];
  const maxHeight =
    orientation === "row" ? "calc(100vh - 100px)" : "calc(50vh - 100px)";

  const onMeasureDragEnd: OnDragEndResponder = ({ destination, source }) => {
    if (!destination) return;

    setContent({
      ...content,
      measures: reorder(content.measures, source.index, destination.index),
    });
  };

  const duplicateAllMeasures = () => {
    setContent({
      ...content,
      measures: [
        ...content.measures,
        ...content.measures.map((measure) => duplicate(measure)),
      ],
    });
  };
  return (
    <>
      <Dialog
        key={"hey"}
        isOpen={!!content}
        onClose={close}
        style={{
          width: orientation === "row" ? "100vw" : undefined,
        }}
        title={
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <p style={{ marginRight: 8 }}>Edit sheet music</p>
            <Icon
              size={12}
              onClick={() =>
                setOrientation(orientation === "row" ? "column" : "row")
              }
              icon={orientation === "row" ? "minimize" : "fullscreen"}
            />
            <Icon
              size={12}
              onClick={() => {
                const element = document.createElement("a");
                const file = new Blob([getXmlFromSheetMeasures(content)], {
                  type: "text/plain",
                });
                element.href = URL.createObjectURL(file);
                element.download = "export.musicxml";
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
              }}
              icon={"download"}
            />
          </div>
        }
      >
        <div
          style={{
            display: orientation === "row" ? "grid" : undefined,
            gap: 12,
            gridTemplateColumns:
              orientation === "row"
                ? "minmax(0, 4fr) minmax(0, 3fr)"
                : undefined,
            alignItems: "stretch",
          }}
        >
          <div
            style={
              orientation === "row"
                ? {
                    maxHeight,
                    alignSelf: "start",
                    overflow: "auto",
                  }
                : {
                    maxHeight,
                    overflow: "auto",
                  }
            }
          >
            <MusicSheetDisplay key={orientation} content={content} />
          </div>
          <div
            style={{
              padding: 16,
              maxHeight,
              overflow: "auto",
            }}
          >
            <div style={{ display: "flex" }}>
              <Label>
                Key
                <Select2<Fifth>
                  filterable={false}
                  itemRenderer={(key, { handleClick }) => (
                    <MenuItem
                      text={displayFifth(key)}
                      key={key}
                      onClick={handleClick}
                    />
                  )}
                  items={fifths}
                  activeItem={content.key?.fifths}
                  onItemSelect={(fifths) => {
                    content.key = { fifths: fifths };
                    setContent({ ...content });
                  }}
                >
                  <Button
                    text={
                      !isNil(content.key)
                        ? displayFifth(content.key.fifths)
                        : "Select a key"
                    }
                    rightIcon="double-caret-vertical"
                  />
                </Select2>
              </Label>
              <Label>
                Clef
                <Select2<Clef>
                  filterable={false}
                  itemRenderer={(key, { handleClick }) => (
                    <MenuItem text={key} key={key} onClick={handleClick} />
                  )}
                  items={clefs}
                  activeItem={content.clef}
                  onItemSelect={(clef) => {
                    content.clef = clef;
                    setContent({ ...content });
                  }}
                >
                  <Button
                    text={content.clef}
                    rightIcon="double-caret-vertical"
                    placeholder="Select a clef"
                  />
                </Select2>
              </Label>
            </div>
            <div style={{}}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Label>Measures</Label>
                <ButtonGroup>
                  <Button icon={"arrow-down"} onClick={transposeAllDown} />
                  <Button icon={"arrow-up"} onClick={transposeAllUp} />
                  <Popover2
                    content={
                      <Menu>
                        <MenuItem
                          onClick={fasterX2}
                          text={"Faster x2"}
                          icon={"fast-forward"}
                        />
                        <MenuItem
                          onClick={slowerX2}
                          text={"Slower x2"}
                          icon={"fast-backward"}
                        />
                        <MenuItem
                          onClick={mergePairs}
                          text={"Merge pairs"}
                          icon={"merge-columns"}
                        />
                        <MenuItem
                          onClick={splitPairs}
                          text={"Split pairs"}
                          icon={"split-columns"}
                        />
                        <MenuItem
                          text={"Remove alterations"}
                          icon={"key-backspace"}
                          onClick={removeAlterations}
                        />
                        <MenuItem
                          text={"Replace notes"}
                          icon={"search-template"}
                          onClick={() => setReplaceAllOcurences(true)}
                        />
                        <MenuItem
                          text={"Duplicate everything"}
                          icon={"duplicate"}
                          onClick={duplicateAllMeasures}
                        />
                      </Menu>
                    }
                  >
                    <Button icon={"more"} />
                  </Popover2>
                </ButtonGroup>
              </div>
              <DragDropContext onDragEnd={onMeasureDragEnd}>
                <Droppable droppableId={"droppable-sheetmusic"}>
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {content.measures.map((measure, index) => (
                        <Draggable
                          key={measure.id}
                          index={index}
                          draggableId={measure.id + ""}
                        >
                          {(provided) => (
                            <div
                              style={{ userSelect: "none" }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              {/*up here*/}
                              <div
                                style={{
                                  display: "flex",
                                  gap: 8,
                                  alignItems: "baseline",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: 4,
                                    alignItems: "baseline",
                                  }}
                                >
                                  <Icon
                                    {...provided.dragHandleProps}
                                    icon={"drag-handle-vertical"}
                                  />
                                  <Icon
                                    icon={
                                      openMeasures.includes(index)
                                        ? "chevron-down"
                                        : "chevron-right"
                                    }
                                    onClick={() => {
                                      if (openMeasures.includes(index)) {
                                        setOpenMeasures([
                                          ...openMeasures.filter(
                                            (num) => num !== index,
                                          ),
                                        ]);
                                      } else {
                                        setOpenMeasures([
                                          ...openMeasures,
                                          index,
                                        ]);
                                      }
                                    }}
                                  />
                                  <H4>{index + 1}. measure</H4>
                                </div>
                                <ButtonGroup>
                                  <Button
                                    icon={"alignment-left"}
                                    intent={
                                      measure.newLine
                                        ? Intent.PRIMARY
                                        : Intent.NONE
                                    }
                                    onClick={() => {
                                      measure.newLine = !measure.newLine;
                                      setContent({ ...content });
                                    }}
                                  />
                                  <Button
                                    icon={"arrow-down"}
                                    onClick={(e) => {
                                      const interval =
                                        e.metaKey ||
                                        e.ctrlKey ||
                                        e.shiftKey ||
                                        e.altKey
                                          ? 1
                                          : 3;
                                      measure.notes = measure.notes.map(
                                        (note) => ({
                                          ...note,
                                          noteName:
                                            usedNotes[
                                              usedNotes.indexOf(
                                                note.noteName!,
                                              ) - interval
                                            ],
                                        }),
                                      );
                                      setContent({ ...content });
                                    }}
                                  />
                                  <Button
                                    icon={"arrow-up"}
                                    onClick={(e) => {
                                      const interval =
                                        e.metaKey ||
                                        e.ctrlKey ||
                                        e.shiftKey ||
                                        e.altKey
                                          ? 1
                                          : 3;
                                      measure.notes = measure.notes.map(
                                        (note) => ({
                                          ...note,
                                          noteName:
                                            usedNotes[
                                              usedNotes.indexOf(
                                                note.noteName!,
                                              ) + interval
                                            ],
                                        }),
                                      );
                                      setContent({ ...content });
                                    }}
                                  />
                                  <Button
                                    icon={"duplicate"}
                                    onClick={() => {
                                      const newElement = duplicate(measure);
                                      content.measures.splice(
                                        index + 1,
                                        0,
                                        newElement,
                                      );
                                      setContent({ ...content });
                                    }}
                                  />
                                  <Button
                                    icon={"trash"}
                                    onClick={() => {
                                      openConfirmDialog({
                                        onSubmit: () => {
                                          content.measures.splice(index, 1); // 2nd parameter means remove one item only
                                          setContent({ ...content });
                                        },
                                      });
                                    }}
                                  />
                                </ButtonGroup>
                              </div>
                              <Collapse isOpen={openMeasures.includes(index)}>
                                {measure.notes.map((note, noteIndex) => (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        padding: 8,
                                        gap: 8,
                                        alignItems: "center",
                                      }}
                                    >
                                      <Select2<NoteName | undefined>
                                        filterable={false}
                                        itemRenderer={(
                                          key,
                                          { handleClick },
                                        ) => (
                                          <MenuItem
                                            text={key || "-"}
                                            key={key}
                                            onClick={handleClick}
                                          />
                                        )}
                                        items={[undefined, ...usedNotes]}
                                        activeItem={note.noteName}
                                        onItemSelect={(newName) => {
                                          note.noteName = newName;
                                          setContent({ ...content });
                                        }}
                                      >
                                        <Button
                                          text={note.noteName}
                                          rightIcon="double-caret-vertical"
                                          placeholder="Select a note"
                                        />
                                      </Select2>
                                      <Select2<NoteDuration>
                                        filterable={false}
                                        itemRenderer={(
                                          key,
                                          { handleClick },
                                        ) => (
                                          <MenuItem
                                            text={key}
                                            key={key}
                                            onClick={handleClick}
                                          />
                                        )}
                                        items={noteDurations}
                                        activeItem={note.duration}
                                        onItemSelect={(newDuration) => {
                                          note.duration = newDuration;
                                          setContent({ ...content });
                                        }}
                                      >
                                        <Button
                                          text={note.duration}
                                          rightIcon="double-caret-vertical"
                                          placeholder="Select a duration"
                                        />
                                      </Select2>
                                      <InputGroup
                                        placeholder="Optional label..."
                                        onChange={(e) => {
                                          note.label = e.target.value;
                                          setContent({ ...content });
                                        }}
                                        value={note.label}
                                        type={"text"}
                                      />
                                      <ButtonGroup>
                                        <Button
                                          icon="arrow-down"
                                          onClick={(e) => {
                                            const interval =
                                              e.metaKey ||
                                              e.ctrlKey ||
                                              e.shiftKey ||
                                              e.altKey
                                                ? 1
                                                : 3;
                                            note.noteName =
                                              usedNotes[
                                                usedNotes.indexOf(
                                                  note.noteName!,
                                                ) - interval
                                              ];
                                            setContent({ ...content });
                                          }}
                                        ></Button>
                                        <Button
                                          disabled={!note.noteName}
                                          icon="arrow-up"
                                          onClick={(e) => {
                                            const interval =
                                              e.metaKey ||
                                              e.ctrlKey ||
                                              e.shiftKey ||
                                              e.altKey
                                                ? 1
                                                : 3;
                                            note.noteName =
                                              usedNotes[
                                                usedNotes.indexOf(
                                                  note.noteName!,
                                                ) + interval
                                              ];
                                            setContent({ ...content });
                                          }}
                                        ></Button>
                                      </ButtonGroup>
                                      <ButtonGroup>
                                        <Button
                                          icon={getDotIcon(note)}
                                          onClick={() => {
                                            updateDot(note);
                                            setContent({ ...content });
                                          }}
                                        />
                                        <Button
                                          icon={getSlurOrTieIcon(note)}
                                          onClick={() => {
                                            updateSlurOrTie(note);
                                            setContent({ ...content });
                                          }}
                                        />
                                        <Button
                                          icon={"duplicate"}
                                          onClick={() => {
                                            measure.notes.splice(noteIndex, 0, {
                                              ...note,
                                            });
                                            setContent({ ...content });
                                          }}
                                        />
                                        <Button
                                          icon={"trash"}
                                          onClick={() => {
                                            openConfirmDialog({
                                              onSubmit: () => {
                                                measure.notes.splice(
                                                  noteIndex,
                                                  1,
                                                );
                                                setContent({ ...content });
                                              },
                                            });
                                          }}
                                        />
                                      </ButtonGroup>
                                    </div>
                                  </>
                                ))}
                                <Button
                                  onClick={() => {
                                    measure.notes.push({
                                      noteName: "C4",
                                      duration: NoteDuration.whole,
                                    });
                                    setContent({ ...content });
                                  }}
                                >
                                  Add Note
                                </Button>
                              </Collapse>
                              {/*down here*/}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <Button
                onClick={() => {
                  content.measures.push({ id: crypto.randomUUID(), notes: [] });
                  setContent({ ...content });
                }}
              >
                Add measure
              </Button>
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button
            intent={Intent.PRIMARY}
            onClick={() => {
              setContent(content);
              close();
            }}
          >
            Save
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog
        title={"Replace all occurences"}
        isOpen={replaceAllOcurences}
        onClose={() => setReplaceAllOcurences(false)}
      >
        <Label>Source</Label>
        <Select2<NoteName | undefined>
          filterable={false}
          itemRenderer={(key, { handleClick }) => (
            <MenuItem text={key || "-"} key={key} onClick={handleClick} />
          )}
          items={
            content.clef === Clef.G
              ? [undefined, ...saxophoneNoteNames]
              : [undefined, ...bassNoteNames]
          }
          activeItem={sourceReplaceNote}
          onItemSelect={setSourceReplaceNote}
        >
          <Button
            text={sourceReplaceNote ?? "-"}
            rightIcon="double-caret-vertical"
            placeholder="Select a note"
          />
        </Select2>
        <Label>Target</Label>
        <Select2<NoteName | undefined>
          filterable={false}
          itemRenderer={(key, { handleClick }) => (
            <MenuItem text={key || "-"} key={key} onClick={handleClick} />
          )}
          items={[undefined, ...usedNotes]}
          activeItem={targetReplaceNote}
          onItemSelect={setTargetReplaceNote}
        >
          <Button
            text={targetReplaceNote ?? "-"}
            rightIcon="double-caret-vertical"
            placeholder="Select a note"
          />
        </Select2>
        <Button text={"Replace all"} onClick={replaceAll} />
      </Dialog>
    </>
  );
}

export default SheetMusicEditorDialog;
