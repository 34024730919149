import { useState } from "react";

export type DialogProps = { isOpen: boolean; onClose: () => void };

export const useDialogState = () => {
  const [isOpen, setIsOpen] = useState(false);
  return {
    open: () => setIsOpen(true),
    dialogProps: {
      isOpen,
      onClose: () => setIsOpen(false),
    },
  };
};
