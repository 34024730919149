import React from "react";
import ReactDOM from "react-dom/client";
import { ConfigurationProvider } from "./contexts/ConfigurationContext";
import { ConfirmDialogContextProvider } from "./contexts/ConfirmDialogContext";
import { ErasProvider } from "./contexts/ErasContext";
import { OpenContextProvider } from "./contexts/OpenContext";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ConfigurationProvider>
      <ErasProvider>
        <ConfirmDialogContextProvider>
          <OpenContextProvider>
            <App />
          </OpenContextProvider>
        </ConfirmDialogContextProvider>
      </ErasProvider>
    </ConfigurationProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
