import React from "react";
import { Button, Icon, Intent } from "@blueprintjs/core";
import { useEras } from "../../../../contexts/ErasContext";
import { Clef, QuizSheetMusicAnswerElement } from "../../../../GameElements";
import { ElementEditorProps } from "./ElementEditorProps";
import SheetMusicEditor from "./SheetMusicElementEditor/SheetMusicEditor";
import {
  TopRightActionsButton,
  useElementActions,
} from "./TopRightActionsButton";

function SaxophoneFingeringAnswersElementEditor(
  props: ElementEditorProps<QuizSheetMusicAnswerElement>,
) {
  const { update } = useEras();
  const actions = useElementActions(props);

  return (
    <TopRightActionsButton {...{ actions }}>
      <div
        style={{
          width: "100%",
          justifyContent: "stretch",
          alignItems: "stretch",
          display: "flex",
          flexWrap: "wrap",
          marginTop: 16,
        }}
      >
        {props.element.answers.map((answer) => (
          <div
            style={{
              padding: 6,
              position: "relative",
            }}
          >
            <div style={{ width: "100%", flex: 1 }}>
              <SheetMusicEditor
                content={answer.content}
                setContent={(c) => {
                  answer.content = c;
                  update();
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: "50%",
              }}
            >
              <Icon
                onClick={() => {
                  answer.value = !answer.value;
                  update();
                }}
                intent={answer.value ? Intent.SUCCESS : Intent.DANGER}
                icon={answer.value ? "tick-circle" : "cross-circle"}
              />
            </div>
          </div>
        ))}
        {props.element.answers.length !== 2 && (
          <Button
            onClick={() => {
              props.element.answers.push({
                content: { clef: Clef.G, measures: [] },
                value: true,
              });
              update();
            }}
          >
            Add Answer
          </Button>
        )}
      </div>
    </TopRightActionsButton>
  );
}

export default SaxophoneFingeringAnswersElementEditor;
