import React from "react";
import { Icon, Intent, Menu, MenuItem, MenuItemProps } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { useConfirmDialog } from "../../../../contexts/ConfirmDialogContext";
import { useEras } from "../../../../contexts/ErasContext";
import { Quiz, QuizElement } from "../../../../GameElements";

export const useElementActions = ({
  quiz,
  element,
  index,
}: {
  element: QuizElement;
  index: number;
  quiz: Quiz;
}): MenuItemProps[] => {
  const { update } = useEras();
  const { openConfirmDialog } = useConfirmDialog();
  const moveUp = () => {
    quiz!.elements.splice(index!, 1);
    quiz!.elements.splice(index! - 1, 0, element!);
    update();
  };
  const moveDown = () => {
    quiz!.elements.splice(index!, 1);
    quiz!.elements.splice(index! + 1, 0, element!);
    update();
  };
  const deleteIt = () => {
    openConfirmDialog({
      onSubmit: () => {
        quiz!.elements.splice(index!, 1);
        update();
      },
    });
  };
  return [
    {
      icon: "arrow-up",
      disabled: index === 0,
      onClick: moveUp,
      text: "Move up",
    },
    {
      icon: "arrow-down",
      disabled: index + 1 === quiz.elements.length,
      onClick: moveDown,
      text: "Move down",
    },
    {
      intent: Intent.DANGER,
      icon: "trash",
      text: "Delete",
      onClick: deleteIt,
    },
  ];
};

export function TopRightActionsButton({
  children,
  actions,
}: {
  children?: React.ReactNode | React.ReactNode[];
  actions?: MenuItemProps[];
}) {
  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", top: -20, right: 0 }}>
        <Popover2
          placement={"bottom-end"}
          minimal
          content={
            <Menu>
              {actions && (
                <>
                  {actions.map((action, index) => (
                    <MenuItem {...{ ...action, key: index }} />
                  ))}
                </>
              )}
            </Menu>
          }
        >
          <Icon icon={"more"} color={"grey"} />
        </Popover2>
      </div>
      {children}
    </div>
  );
}
