import React from "react";
import { EditableText, H4, Icon, Intent, Tag } from "@blueprintjs/core";
import { useConfiguration } from "../../contexts/ConfigurationContext";
import { useConfirmDialog } from "../../contexts/ConfirmDialogContext";
import { useEras } from "../../contexts/ErasContext";
import { useOpenContext } from "../../contexts/OpenContext";
import { duplicate, newQuiz } from "../../utils";
import { Lesson, Level } from "../../GameElements";
import MoreButton from "./QuizEditor/MoreButton";
import QuizEditor from "./QuizEditor/QuizEditor";

type LessonEditorProps = {
  lesson: Lesson;
  level: Level;
  previousLevel?: Level;
  index: number;
};

function LessonEditor({
  lesson,
  level,
  index,
  previousLevel,
}: LessonEditorProps) {
  const { update } = useEras();
  const {
    configuration: { deviceWidth, deviceHeight },
  } = useConfiguration();
  const { isOpen, toggle } = useOpenContext();
  const moveUp = () => {
    level.lessons.splice(index, 1);
    if (index === 0) {
      previousLevel?.lessons.push(lesson);
    } else {
      level.lessons.splice(index - 1, 0, lesson);
    }
    update();
  };
  const mergeUp = () => {
    level.lessons.splice(index, 1);
    level.lessons[index - 1]?.quizes.push(...lesson.quizes);
    update();
  };
  const moveDown = () => {
    level.lessons.splice(index, 1);
    level.lessons.splice(index + 1, 0, lesson);
    update();
  };
  const { openConfirmDialog } = useConfirmDialog();
  const deleteIt = () => {
    openConfirmDialog({
      onSubmit: () => {
        level.lessons.splice(index, 1);
        update();
      },
    });
  };
  const duplicateLesson = () => {
    const newElement = duplicate(lesson);
    level.lessons.splice(index + 1, 0, newElement);
    update();
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          gap: 2,
          padding: 4,
        }}
      >
        <div style={{ alignSelf: "baseline" }}>
          <Icon
            onClick={() => toggle(lesson.id)}
            size={18}
            icon={isOpen(lesson.id) ? "chevron-down" : "chevron-right"}
          />
        </div>
        <H4>
          <EditableText
            placeholder={"Lesson name..."}
            onConfirm={(name) => {
              lesson.name = name;
              update();
            }}
            defaultValue={lesson.name}
          />
        </H4>
        <Tag round minimal>
          <span>
            <b>{lesson.quizes.length}</b> quizes
          </span>
        </Tag>
        <MoreButton
          size={18}
          actions={[
            {
              icon: "double-chevron-up",
              disabled: index === 0,
              onClick: mergeUp,
              text: "Merge up",
            },
            {
              icon: "arrow-up",
              onClick: moveUp,
              disabled: index === 0,
              text: "Move up",
            },
            {
              icon: "arrow-down",
              disabled: index + 1 === level.lessons.length,
              onClick: moveDown,
              text: "Move down",
            },
            {
              icon: "duplicate",
              onClick: duplicateLesson,
              text: "Duplicate",
            },
            {
              intent: Intent.DANGER,
              icon: "trash",
              text: "Delete",
              onClick: deleteIt,
            },
          ]}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflowX: "auto",
          gap: 20,
          paddingLeft: 16,
        }}
      >
        {isOpen(lesson.id) && (
          <>
            {lesson.quizes.map((quiz, index) => (
              <QuizEditor
                lesson={lesson}
                quiz={quiz}
                index={index}
                key={quiz.id}
                count={lesson.quizes.length}
              />
            ))}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: deviceWidth,
                minWidth: deviceWidth,
                height: deviceHeight,
                minHeight: deviceHeight,
                background: "lightgrey",
              }}
              onClick={() => {
                lesson.quizes.push(newQuiz());
                update();
              }}
            >
              <Icon icon={"plus"} size={52} color={"grey"} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default LessonEditor;
