import React, { useState } from "react";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import {
  Button,
  FormGroup,
  H1,
  InputGroup,
  Intent,
  NonIdealState,
} from "@blueprintjs/core";
import { firebaseApp } from "../firebase";
import { getAuth } from "firebase/auth";

function LoginScreen() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signInWithEmailAndPassword, user, loading, error] =
    useSignInWithEmailAndPassword(getAuth(firebaseApp));

  return (
    <div style={{ maxWidth: 300, padding: 16, gap: 16 }}>
      <H1>Login</H1>
      <FormGroup label="Email">
        <InputGroup
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormGroup>
      <FormGroup label="Password">
        <InputGroup
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </FormGroup>
      {error && <NonIdealState description={error.message} />}
      <Button
        loading={loading}
        onClick={() => signInWithEmailAndPassword(email, password)}
        intent={user ? Intent.SUCCESS : undefined}
      >
        Sign In
      </Button>
    </div>
  );
}

export default LoginScreen;
