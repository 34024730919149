import React from "react";
import { EditableText, H1, H2, Icon, Intent, Tag } from "@blueprintjs/core";
import { useConfirmDialog } from "../../contexts/ConfirmDialogContext";
import { useEras } from "../../contexts/ErasContext";
import { useOpenContext } from "../../contexts/OpenContext";
import { duplicate, newLevel } from "../../utils";
import { Era } from "../../GameElements";
import LevelEditor from "./LevelEditor";
import MoreButton from "./QuizEditor/MoreButton";

type EraEditorProps = {
  eras: Era[];
  era: Era;
  index: number;
};

function EraEditor({ eras, era, index }: EraEditorProps) {
  const { update } = useEras();
  const moveUp = () => {
    eras[index - 1].index++;
    eras[index].index--;
    update();
  };
  const moveDown = () => {
    eras[index].index++;
    eras[index + 1].index--;
    update();
  };
  const { openConfirmDialog } = useConfirmDialog();
  const deleteIt = () => {
    openConfirmDialog({
      onSubmit: () => {
        eras.splice(index, 1);
        update();
      },
    });
  };
  const duplicateEra = () => {
    const newElement = duplicate(era);
    eras.splice(index + 1, 0, newElement);
    update();
  };
  const { isOpen, toggle } = useOpenContext();
  return (
    <div style={{ gap: 16 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 8,
          padding: 6,
        }}
      >
        <Icon
          style={{ alignSelf: "baseline" }}
          onClick={() => toggle(era.id)}
          size={28}
          icon={isOpen(era.id) ? "chevron-down" : "chevron-right"}
        />
        <H1>
          <EditableText
            placeholder={"Era name..."}
            onConfirm={(name) => {
              era.name = name;
              update();
            }}
            defaultValue={era.name}
          />
        </H1>
        <Tag large round>
          <span>
            <b>{era.levels.length}</b> levels
          </span>
        </Tag>
        <MoreButton
          size={28}
          actions={[
            {
              icon: "arrow-up",
              disabled: index === 0,
              onClick: moveUp,
              text: "Move up",
            },
            {
              icon: "arrow-down",
              disabled: index + 1 === eras.length,
              onClick: moveDown,
              text: "Move down",
            },
            {
              icon: "duplicate",
              onClick: duplicateEra,
              text: "Duplicate",
            },
            {
              intent: Intent.DANGER,
              icon: "trash",
              text: "Delete",
              onClick: deleteIt,
            },
          ]}
        />
      </div>
      {isOpen(era.id) && (
        <div style={{ paddingLeft: 16 }}>
          {era.levels.map((level, index) => (
            <LevelEditor index={index} era={era} level={level} key={level.id} />
          ))}
          <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
            <Icon
              style={{ alignSelf: "baseline" }}
              size={22}
              icon={"chevron-right"}
            />
            <H2>
              <EditableText
                key={era.levels.length}
                placeholder={"Add level..."}
                onConfirm={(name) => {
                  era.levels.push(newLevel(name));
                  update();
                }}
              />
            </H2>
          </div>
        </div>
      )}
    </div>
  );
}

export default EraEditor;
