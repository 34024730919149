import React from "react";
import { useEras } from "../../../../contexts/ErasContext";
import { QuizRemoteImageElement } from "../../../../GameElements";
import { ElementEditorProps } from "./ElementEditorProps";
import RemoteImageEditor from "./RemoteImageEditor";
import {
  TopRightActionsButton,
  useElementActions,
} from "./TopRightActionsButton";

function RemoteImageElementEditor(
  props: ElementEditorProps<QuizRemoteImageElement>,
) {
  const { update } = useEras();
  const actions = useElementActions(props);
  return (
    <TopRightActionsButton {...{ actions }}>
      <RemoteImageEditor
        src={props.element.src}
        setSrc={(src) => {
          props.element.src = src;
          update();
        }}
      />
    </TopRightActionsButton>
  );
}

export default RemoteImageElementEditor;
