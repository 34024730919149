import CodeMirror from "@uiw/react-codemirror";
import React, { useState } from "react";
import { Button, Dialog } from "@blueprintjs/core";
import { jsonSchema } from "codemirror-json-schema";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";

const extensions = [
  jsonSchema({
    type: "object",
    properties: {
      example: {
        type: "boolean",
      },
    },
  }),
];

type RawElementDialogProps<T> = {
  object?: T;
  close: () => void;
  update: (newObject: T) => void;
};

function InspectorDialog<T>({
  object,
  close,
  update,
}: RawElementDialogProps<T>) {
  const [localCode, setLocalCode] = useState(JSON.stringify(object, null, 2));
  return (
    <Dialog
      style={{ minWidth: "80%" }}
      isOpen={!!object}
      onClose={close}
      title={"Inspector"}
    >
      <CodeMirror
        extensions={extensions}
        value={localCode}
        maxHeight={"80vh"}
        onChange={setLocalCode}
        lang={"json"}
        theme={vscodeDark}
      />
      <div style={{ padding: 4, gap: 4, display: "flex" }}>
        <Button onClick={() => setLocalCode(JSON.stringify(object, null, 2))}>
          Reset
        </Button>
        <Button
          intent={"primary"}
          onClick={() => {
            update(JSON.parse(localCode));
            close();
          }}
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
}

export default InspectorDialog;
