import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, H4, Label } from "@blueprintjs/core";

type OpenArgs = { onSubmit: () => void };
const ConfirmDialogContext = createContext<{
  openConfirmDialog: (args: OpenArgs) => void;
}>({
  openConfirmDialog: () => {},
});
export const ConfirmDialogContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [openArgs, setOpenArgs] = useState<OpenArgs>();
  const dialogRef = useRef<HTMLDialogElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (openArgs) {
      dialogRef.current?.showModal();
      buttonRef.current?.focus();
    } else {
      dialogRef.current?.close();
    }
  }, [openArgs]);
  return (
    <ConfirmDialogContext.Provider
      value={{
        openConfirmDialog: setOpenArgs,
      }}
    >
      <dialog
        ref={dialogRef}
        title={"Confirm"}
        onClose={() => setOpenArgs(undefined)}
      >
        <H4>Confirm</H4>
        <Label>Are you sure?</Label>
        <div style={{ display: "flex", gap: 8 }}>
          <Button text={"Cancel"} onClick={() => setOpenArgs(undefined)} />
          <Button
            elementRef={buttonRef}
            intent={"danger"}
            text={"Confirm"}
            onClick={() => {
              openArgs?.onSubmit();
              setOpenArgs(undefined);
            }}
          />
        </div>
      </dialog>
      {children}
    </ConfirmDialogContext.Provider>
  );
};
export const useConfirmDialog = () => useContext(ConfirmDialogContext);
