import React from "react";
import { Button, Icon, Intent } from "@blueprintjs/core";
import { useEras } from "../../../../contexts/ErasContext";
import { QuizRemoteImageAnswersElement } from "../../../../GameElements";
import { ElementEditorProps } from "./ElementEditorProps";
import RemoteImageEditor from "./RemoteImageEditor";

function RemoteImageAnswersElementEditor(
  props: ElementEditorProps<QuizRemoteImageAnswersElement>,
) {
  const { update } = useEras();

  return (
    <div
      style={{
        width: "100%",
        justifyContent: "stretch",
        alignItems: "stretch",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {props.element.answers.map((answer) => (
        <div
          style={{
            width: "50%",
            aspectRatio: 1,
            padding: 6,
            position: "relative",
          }}
        >
          <div style={{ width: "100%", flex: 1 }}>
            <RemoteImageEditor
              src={answer.content.src}
              setSrc={(s) => {
                answer.content.src = s;
                update();
              }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: "50%",
            }}
          >
            <Icon
              onClick={() => {
                answer.value = !answer.value;
                update();
              }}
              intent={answer.value ? Intent.SUCCESS : Intent.DANGER}
              icon={answer.value ? "tick-circle" : "cross-circle"}
            />
          </div>
        </div>
      ))}
      {props.element.answers.length !== 4 && (
        <Button
          onClick={() => {
            props.element.answers.push({ content: { src: "" }, value: true });
            update();
          }}
        >
          Add Answer
        </Button>
      )}
    </div>
  );
}

export default RemoteImageAnswersElementEditor;
