import { useEffect, useState } from "react";
import { useConfiguration } from "../contexts/ConfigurationContext";
import { firebaseApp } from "../firebase";
import { getStorage, list, ref } from "firebase/storage";

const storage = getStorage(firebaseApp);
export const useListFiles = (folderUrl: string) => {
  const [files, setFiles] = useState<{ name: string; url: string }[]>([]);
  useEffect(() => {
    const perform = async () => {
      const result = await list(ref(storage, folderUrl));
      setFiles(
        result.items.map(({ name }) => ({ name, url: folderUrl + "/" + name })),
      );
    };
    perform();
  }, [folderUrl]);
  return files;
};
export const useListSounds = () => {
  const {
    configuration: { soundServer },
  } = useConfiguration();
  return useListFiles(soundServer);
};
export const useListImages = () => {
  const {
    configuration: { imageServer },
  } = useConfiguration();
  return useListFiles(imageServer);
};
