import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyD_sW7TTxGJwOxm1NVWIAqKL8sZuxOmxj8",
  authDomain: "go-saxo.firebaseapp.com",
  databaseURL: "https://go-saxo.firebaseio.com",
  projectId: "go-saxo",
  storageBucket: "go-saxo.appspot.com",
  messagingSenderId: "945438333348",
  appId: "1:945438333348:web:d95a49f2530c1cd7bc0931",
  measurementId: "G-GME17B61LK",
};
export const firebaseApp = initializeApp(firebaseConfig);
