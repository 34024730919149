import React, { createContext, useContext, useEffect, useState } from "react";
import { useRemoteEras } from "../hooks/useRemoteEras";
import * as _ from "lodash";
import { cloneDeep, isEqual, isNil } from "lodash";
import { Era } from "../GameElements";

export function omitByRecursivelyInPlace<T extends object | null | undefined>(
  value: T,
  iteratee: (v: any, k: string) => any,
) {
  _.each(value, (v, k) => {
    // no longer an if/else
    if (_.isObject(v)) {
      omitByRecursivelyInPlace(v, iteratee);
    }
    if (iteratee(v, k)) {
      // check for array types
      if (_.isArray(value)) _.pullAt(value, [parseInt(k)]);
      else _.unset(value, k);
    }
  });
  return value;
}

const ErasContext = createContext<{
  eras?: Era[];
  remoteEras?: Era[];
  setEras: (eras?: Era[]) => void;
  update: () => void;
  hasUnpublishedChanges: boolean;
  isPublishingChanges: boolean;
  publishChanges: () => void;
}>({
  setEras: () => {},
  update: () => {},
  hasUnpublishedChanges: false,
  isPublishingChanges: false,
  publishChanges: () => {},
});
export const ErasProvider = ({ children }: { children: React.ReactNode }) => {
  const [eras, setEras] = useState<Era[]>();
  const { remoteEras, save } = useRemoteEras();
  const hasUnpublishedChanges = !isEqual(remoteEras, eras);
  const [isPublishingChanges, setIsPublishingChanges] = useState(false);

  useEffect(() => {
    window.onbeforeunload = hasUnpublishedChanges
      ? () =>
          "You have unpublished changes. Make sure to save them before leaving."
      : null;
  }, [hasUnpublishedChanges]);

  useEffect(() => {
    if (!eras && remoteEras) {
      setEras(remoteEras);
    }
  }, [remoteEras, eras, setEras]);

  return (
    <ErasContext.Provider
      value={{
        eras: _.orderBy(eras, ["index"], ["asc"]),
        remoteEras,
        setEras,
        hasUnpublishedChanges,
        isPublishingChanges,
        update: () => setEras(cloneDeep(eras)),
        publishChanges: () => {
          if (eras && !isPublishingChanges) {
            setIsPublishingChanges(true);
            save(omitByRecursivelyInPlace(eras, isNil))
              .then((newEras) => setEras(newEras))
              .finally(() => setIsPublishingChanges(false));
          }
        },
      }}
    >
      {children}
    </ErasContext.Provider>
  );
};
export const useEras = () => useContext(ErasContext);
