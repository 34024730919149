import React from "react";
import { useEras } from "../../../../../contexts/ErasContext";
import { cloneDeep } from "lodash";
import { QuizSheetMusicElement } from "../../../../../GameElements";
import { ElementEditorProps } from "../ElementEditorProps";
import { useElementActions } from "../TopRightActionsButton";
import SheetMusicEditor from "./SheetMusicEditor";

const MusicSheetComponent = (
  props: ElementEditorProps<QuizSheetMusicElement> & { viewOnly?: boolean },
) => {
  const { update } = useEras();

  const actions = useElementActions(props);
  return (
    <SheetMusicEditor
      actions={actions}
      content={props.element.content}
      setContent={(c) => {
        props.element.content = cloneDeep(c);
        update();
      }}
    />
  );
};
export default MusicSheetComponent;
