import React from "react";
import { EditableText } from "@blueprintjs/core";
import { useEras } from "../../../../contexts/ErasContext";
import { QuizMetronomeElement } from "../../../../GameElements";
import { ElementEditorProps } from "./ElementEditorProps";
import {
  TopRightActionsButton,
  useElementActions,
} from "./TopRightActionsButton";

function MetronomeElementEditor(
  props: ElementEditorProps<QuizMetronomeElement>,
) {
  const { update } = useEras();
  const actions = useElementActions(props);
  return (
    <TopRightActionsButton {...{ actions }}>
      <p>
        Metronome. Bpm:{" "}
        <EditableText
          placeholder={"Note..."}
          onConfirm={(t) => {
            props.element.bpm = Number(t);
            update();
          }}
          defaultValue={props.element.bpm + ""}
        />
        Beats:{" "}
        <EditableText
          placeholder={"Note..."}
          onConfirm={(t) => {
            props.element.beats = Number(t);
            update();
          }}
          defaultValue={props.element.beats + ""}
        />
      </p>
    </TopRightActionsButton>
  );
}

export default MetronomeElementEditor;
