import React, { CSSProperties, useEffect, useId, useState } from "react";
import { renderSheetMusicToHtml } from "../../../../../GameElements/utils";
import { SheetMusicContent } from "../../../../../GameElements";

const MusicSheetDisplay = ({
  content,
  style,
}: {
  content: SheetMusicContent;
  style?: CSSProperties;
}) => {
  const id = useId();
  const [aspectRatio, setAspectRatio] = useState<string>("auto");
  const [state, setState] = useState(0);

  useEffect(() => {
    if (state === 0) setState(1);
  }, [state]);

  useEffect(() => {
    const handler = (event: MessageEvent<any>) => {
      const data = event.data;
      // console.log({ data });
      if (data.id === id && data.aspectRatio) {
        setAspectRatio(data.aspectRatio);
      }
    };
    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, [id]);
  try {
    const html = renderSheetMusicToHtml(content, id);

    return (
      <iframe
        title={"Music"}
        key={state}
        srcDoc={html}
        style={{ width: "100%", overflow: "clip", aspectRatio, ...style }}
      />
    );
  } catch (e) {
    return <p>Error loading sheet music</p>;
  }
};
export default MusicSheetDisplay;
