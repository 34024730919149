import React from "react";
import { EditableText } from "@blueprintjs/core";
import { useEras } from "../../../../contexts/ErasContext";
import {
  QuizSaxophoneFingeringElement,
  SaxophoneNoteName,
  saxophoneNoteNames,
} from "../../../../GameElements";
import { ElementEditorProps } from "./ElementEditorProps";
import { SaxophoneFingeringDisplay } from "./SaxophoneFingeringDisplay";
import {
  TopRightActionsButton,
  useElementActions,
} from "./TopRightActionsButton";

function SaxophoneFingeringElementEditor(
  props: ElementEditorProps<QuizSaxophoneFingeringElement>,
) {
  const { update } = useEras();
  const actions = useElementActions(props);

  return (
    <TopRightActionsButton {...{ actions }}>
      <SaxophoneFingeringDisplay {...props.element} />
      <div style={{ display: "flex" }}>
        <EditableText
          placeholder={"Note..."}
          onConfirm={(t) => {
            if (saxophoneNoteNames.includes(t as SaxophoneNoteName)) {
              props.element.noteName = t as SaxophoneNoteName;
            }
            update();
          }}
          defaultValue={props.element.noteName}
        />
        -
        <EditableText
          placeholder={"Alt..."}
          onConfirm={(t) => {
            props.element.alternative = Number(t);
            update();
          }}
          defaultValue={props.element.alternative + ""}
        />
      </div>
    </TopRightActionsButton>
  );
}

export default SaxophoneFingeringElementEditor;
