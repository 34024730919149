import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection } from "react-firebase-hooks/firestore";
import { OverlayToaster, Position } from "@blueprintjs/core";
import { Era, QuizElementType } from "../GameElements";
import { firebaseApp } from "../firebase";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";

const AppToaster = OverlayToaster.create({
  position: Position.BOTTOM,
});
const remoreErasQuery = query(
  collection(getFirestore(firebaseApp), "eras"),
  orderBy("index"),
);
export const useRemoteEras = () => {
  const [user] = useAuthState(getAuth(firebaseApp));
  const [erasData, loading, error] = useCollection(
    user?.uid ? remoreErasQuery : undefined,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );
  const remoteEras = erasData?.docs.map((d) => d.data()) as Era[];
  return {
    remoteEras,
    save: async (erasArg: Era[]) => {
      const eras = erasArg.map((era) => ({
        ...era,
        levels: era.levels.map((level) => ({
          ...level,
          lessons: level.lessons.map((lesson) => ({
            ...lesson,
            quizes: lesson.quizes.map((quiz) => ({
              ...quiz,
              elements: quiz.elements.map((element) => {
                if (element.type === QuizElementType.sheetMusic) {
                  return {
                    ...element,
                    content: {
                      ...element.content,
                      measures: element.content.measures.map((measure) => ({
                        ...measure,
                        id: measure.id ?? crypto.randomUUID(),
                      })),
                    },
                  };
                }
                return element;
              }),
            })),
          })),
        })),
      }));
      try {
        for (let i = remoteEras.length; i < eras.length; i++) {
          const era = eras[i];
          await addDoc(collection(getFirestore(firebaseApp), "eras"), era);
        }

        for (let i = 0; i < eras.length; i++) {
          const era = eras[i];
          const remoteEraId = erasData?.docs[i]?.id;
          if (remoteEraId)
            await setDoc(
              doc(getFirestore(firebaseApp), "eras", remoteEraId),
              era,
            );
        }
        AppToaster.show({
          timeout: 1000,
          intent: "success",
          message: "Successfully saved.",
        });
        return (await getDocs(remoreErasQuery)).docs.map((doc) =>
          doc.data(),
        ) as Era[];
      } catch (e) {
        console.log(e);
        AppToaster.show({
          timeout: 0, //stay till removed
          intent: "danger",
          message:
            "Error while saving (keeping changes)." +
            JSON.stringify(e, Object.getOwnPropertyNames(e), 2),
        });
        throw e;
      }
    },
    loading,
    error,
  };
};
