import React, { useState } from "react";
import { Icon, Intent, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { useConfirmDialog } from "../../../contexts/ConfirmDialogContext";
import { useEras } from "../../../contexts/ErasContext";
import { duplicate, newElement } from "../../../utils";
import { Lesson, Quiz, QuizElementType } from "../../../GameElements";
import InspectorDialog from "./InspectorDialog";

type AddButtonProps = { quiz: Quiz; lesson: Lesson; index: number };

function QuizMoreButton({ quiz, index, lesson }: AddButtonProps) {
  const { update } = useEras();
  const [inspectorOpen, setInspectorOpen] = useState(false);
  const add = (elementType: QuizElementType) => {
    quiz.elements.push(newElement(elementType));
    update();
  };
  const moveLeft = () => {
    lesson.quizes.splice(index, 1);
    lesson.quizes.splice(index - 1, 0, quiz);
    update();
  };

  const moveRight = () => {
    lesson.quizes.splice(index, 1);
    lesson.quizes.splice(index + 1, 0, quiz);
    update();
  };
  const duplicateQuiz = () => {
    const newElement = duplicate(quiz);
    lesson.quizes.splice(index + 1, 0, newElement);
    update();
  };
  const { openConfirmDialog } = useConfirmDialog();
  const deleteIt = () => {
    openConfirmDialog({
      onSubmit: () => {
        lesson.quizes.splice(index, 1);
        update();
      },
    });
  };
  return (
    <>
      <Popover2
        placement={"bottom-end"}
        minimal
        content={
          <Menu>
            <MenuDivider title={"Add"} />
            <MenuItem
              icon={"font"}
              text={"Add Text"}
              onClick={() => add(QuizElementType.body)}
            />
            <MenuItem
              icon={"media"}
              text={"Add Image"}
              onClick={() => add(QuizElementType.remoteImage)}
            />
            <MenuItem
              icon={"music"}
              text={"Add Sound"}
              onClick={() => add(QuizElementType.remoteSound)}
            />
            <MenuItem
              icon={"layout-skew-grid"}
              text={"Add Saxophone fingering"}
              onClick={() => add(QuizElementType.saxophoneFingering)}
            />
            <MenuItem
              icon={"time"}
              text={"Add Metronome"}
              onClick={() => add(QuizElementType.metronome)}
            />
            <MenuItem
              icon={"music"}
              text={"Add Sheet music"}
              onClick={() => add(QuizElementType.sheetMusic)}
            />
            <MenuItem icon={"align-justify"} text={"Add Answers"}>
              <MenuItem
                icon={"font"}
                text={"Add Textes"}
                onClick={() => add(QuizElementType.textAnswers)}
              />
              <MenuItem
                icon={"media"}
                text={"Add Images"}
                onClick={() => add(QuizElementType.remoteImageAnswers)}
              />
              <MenuItem
                icon={"music"}
                text={"Add Music sheets"}
                onClick={() => add(QuizElementType.sheetMusicAnswers)}
              />
              <MenuItem
                icon={"layout-skew-grid"}
                text={"Add Saxophone fingerings"}
                onClick={() => add(QuizElementType.saxophoneFingeringAnswers)}
              />
            </MenuItem>
            <MenuDivider title={"Quiz"} />
            <MenuItem
              icon={"arrow-left"}
              disabled={index === 0}
              onClick={moveLeft}
              text={"Move left"}
            />
            <MenuItem
              icon={"arrow-right"}
              disabled={index + 1 === quiz.elements.length}
              onClick={moveRight}
              text={"Move right"}
            />
            <MenuItem
              icon={"duplicate"}
              onClick={duplicateQuiz}
              text={"Duplicate"}
            />
            <MenuItem
              intent={Intent.DANGER}
              icon={"trash"}
              text={"Delete"}
              onClick={deleteIt}
            />
            <MenuDivider title={"Advanced"} />
            <MenuItem
              icon={"search-template"}
              text={"Inspector"}
              onClick={() => setInspectorOpen(true)}
            />
          </Menu>
        }
      >
        <Icon icon={"more"} />
      </Popover2>
      {inspectorOpen && (
        <InspectorDialog
          close={() => setInspectorOpen(false)}
          object={quiz}
          update={(newQuiz) => {
            lesson.quizes[index] = newQuiz;
            lesson.quizes = [...lesson.quizes];
            update();
          }}
        />
      )}
    </>
  );
}

export default QuizMoreButton;
