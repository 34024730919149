import {
  Clef,
  Dot,
  Key,
  NoteDuration,
  NoteName,
  SheetChord,
  SheetChordType,
  SheetMeasure,
  SheetMusicContent,
  SheetNote,
  Slur,
  Tie,
} from "../index";

const durations: { [key in NoteDuration]: number } = {
  whole: 8,
  half: 4,
  quarter: 2,
  eighth: 1,
  [NoteDuration.sixteenth]: 1,
};
const tempos: { [key in NoteDuration]: number } = {
  whole: 4,
  half: 2,
  quarter: 1,
  eighth: 0.5,
  [NoteDuration.sixteenth]: 0.25,
};
const beams: { [key in NoteDuration]: number } = {
  whole: 0,
  half: 0,
  quarter: 0,
  eighth: 1,
  [NoteDuration.sixteenth]: 2,
};

function getBeam(
  previousNote: SheetNote | undefined,
  note: SheetNote,
  nextNote: SheetNote | undefined,
  currentTempo: number,
) {
  const sameAsPreviousNote =
    previousNote?.duration === note?.duration &&
    previousNote.noteName &&
    note.noteName;
  const sameAsNextNote =
    note?.duration === nextNote?.duration && note.noteName && nextNote.noteName;
  const forceEnd = currentTempo === 2.5;
  const forceStart = currentTempo === 3;
  const beamNumber = beams[note?.duration];
  if (beamNumber === 0) return "";
  if (sameAsPreviousNote) {
    if (sameAsNextNote) {
      if (forceStart) {
        return `<beam number="${beamNumber}">begin</beam>`;
      } else if (forceEnd) {
        return `<beam number="${beamNumber}">end</beam>`;
      }
      return `<beam number="${beamNumber}">continue</beam>`;
    } else {
      return `<beam number="${beamNumber}">end</beam>`;
    }
  } else if (sameAsNextNote) {
    return `<beam number="${beamNumber}">begin</beam>`;
  } else {
    return "";
  }
}

function isSeventh(chordType: SheetChordType) {
  return [
    SheetChordType.majorSeventh,
    SheetChordType.minorSeventh,
    SheetChordType.dominant,
  ].includes(chordType);
}

function renderChord({ root, type }: SheetChord) {
  const { name } = deconstruct(root);
  const seventh = isSeventh(type);
  return `<harmony print-frame="no">
    <root>
      <root-step>${name}</root-step>
    </root>
    <kind 
      ${seventh ? `text="7"` : ``} 
      use-symbols="yes">${type}</kind>
    </harmony>`;
}

function wrapInMeasure({
  index,
  measure,
  content,
  clef,
  key,
}: {
  index: number;
  clef: Clef;
  key: Key | undefined;
} & XmlResult) {
  const attributes =
    index === 0
      ? `<attributes>
        <time><beats>4</beats><beat-type>4</beat-type></time>
          ${
            clef === Clef.F
              ? `<clef><sign>F</sign><line>4</line></clef>`
              : `<clef><sign>G</sign><line>2</line></clef>`
          }
        ${key ? `<key><fifths>${key.fifths}</fifths></key>` : ``}
        </attributes>
      `
      : "";
  const maybeNewLine = measure?.newLine ? `<print new-system="yes"/>` : ``;
  return `<measure number="${
    index + 1
  }">${maybeNewLine}${attributes}${content}</measure>`;
}

export function getXmlFromSheetMeasures(sheetNotes: SheetMusicContent) {
  const { clef, key } = sheetNotes;
  const content = sheetNotes.measures
    .map(getXmlFromMeasure)
    .map(({ content, measure }, index) => ({
      content,
      index,
      measure,
      clef,
      key,
    }))
    .map(wrapInMeasure)
    .join("");
  return clef === Clef.G ? gXml(content) : fXml(content);
}

const deconstruct = (noteName?: NoteName) => {
  return {
    name: noteName?.replace(/[0-9]|#|b/g, ""),
    octave: noteName?.replace(/[A-G]|#|b/g, ""),
    alteration: noteName?.includes("#")
      ? "sharp"
      : noteName?.includes("b")
      ? `flat`
      : "natural",
  };
};
type XmlResult = { content: string; measure: SheetMeasure };

function getXmlFromMeasure(measure: SheetMeasure): XmlResult {
  const { notes } = measure;
  let content = "";
  let currentTempo = 1;
  for (let i = 0; i < notes.length; i++) {
    const note = notes[i]!;
    const { noteName, duration, label } = note;
    const tempo = tempos[duration];

    const previousNote = i === 0 ? undefined : notes[i - 1];
    const nextNote = i === notes.length - 1 ? undefined : notes[i + 1];

    const durationNumber = durations[duration];
    const { name, alteration, octave } = deconstruct(noteName);
    const alter =
      alteration === "sharp"
        ? "<alter>1</alter>"
        : alteration === "flat"
        ? `<alter>-1</alter>`
        : "";
    const noteContent = !noteName
      ? `<rest/>`
      : `<pitch>
					<step>${name}</step>
					${alter}
					<octave>${octave}</octave>
				</pitch>`;

    const beam = getBeam(previousNote, note, nextNote, currentTempo);
    const chord = note.chord ? renderChord(note.chord) : "";
    const tie =
      note.tie === Tie.startTop
        ? `<tied type="start" placement="above" number="1"/>`
        : note.tie === Tie.startBottom
        ? `<tied type="start" placement="below" number="1"/>`
        : note.tie === Tie.stop
        ? `<tied type="stop" placement="above" number="1"/>`
        : "";
    const slur =
      note.slur === Slur.startTop
        ? `<slur type="start" placement="above" number="1"/>`
        : note.slur === Slur.startBottom
        ? `<slur type="start" placement="below" number="1"/>`
        : note.slur === Slur.stop
        ? `<slur type="stop" placement="above" number="1"/>`
        : "";
    const dots =
      note.dot === Dot.single
        ? `<dot/>`
        : note.dot === Dot.double
        ? `<dot/><dot/>`
        : "";

    content += `${chord}<note>
                ${noteContent}
				<duration>${durationNumber}</duration>
				<voice>1</voice>
                <type>${duration}</type>
				${
          !!label
            ? `<lyric number="1"><syllabic>single</syllabic><text>${label}</text></lyric>`
            : ``
        }
				${beam}
				${dots}
				 <notations>${tie}${slur}</notations>
			</note>`;
    currentTempo += tempo;
  }

  return { content, measure };
}

export const gXml = (content: string) => `<?xml version="1.0" ?>
<!DOCTYPE score-partwise>
<score-partwise version="3.1">
	<part-list>
		<score-part id="P1">
			<part-name>Alto Saxophone</part-name>
			<part-abbreviation>A. Sax.</part-abbreviation>
			<score-instrument id="P1-I1">
				<instrument-name>Alto Saxophone</instrument-name>
			</score-instrument>
			<midi-instrument id="P1-I1">
				<midi-channel>1</midi-channel>
				<midi-program>66</midi-program>
				<volume>100</volume>
			</midi-instrument>
		</score-part>
	</part-list>
	<part id="P1">${content}</part>
</score-partwise>
`;
export const fXml = (content: string) => `<?xml version="1.0" ?>
<!DOCTYPE score-partwise>
<score-partwise version="3.1">
	 <part-list>
    <score-part id="P1">
      <part-name>Bass</part-name>
      <part-abbreviation>B.</part-abbreviation>
      <score-instrument id="P1-I1">
        <instrument-name>Bass</instrument-name>
        </score-instrument>
      <midi-device id="P1-I1" port="1"></midi-device>
      <midi-instrument id="P1-I1">
        <midi-channel>1</midi-channel>
        <midi-program>53</midi-program>
        <volume>78.7402</volume>
        <pan>0</pan>
        </midi-instrument>
      </score-part>
    </part-list>
	<part id="P1">${content}</part>
</score-partwise>`;
