import React from "react";
import { Quiz, QuizElement, QuizElementType } from "../../GameElements";
import BodyElement from "./QuizEditor/ElementEditor/BodyElementEditor";
import MetronomeElementEditor from "./QuizEditor/ElementEditor/MetronomeElementEditor";
import RemoteImageAnswersElementEditor from "./QuizEditor/ElementEditor/RemoteImageAnswersElementEditor";
import RemoteImageElementEditor from "./QuizEditor/ElementEditor/RemoteImageElementEditor";
import RemoteSoundElementEditor from "./QuizEditor/ElementEditor/RemoteSoundElementEditor";
import SaxophoneFingeringAnswersElementEditor from "./QuizEditor/ElementEditor/SaxophoneFingeringAnswersElementEditor";
import SaxophoneFingeringElementEditor from "./QuizEditor/ElementEditor/SaxophoneFingeringElementEditor";
import SheetMusicAnswersElementEditor from "./QuizEditor/ElementEditor/SheetMusicAnswersElementEditor";
import SheetMusicElementEditor from "./QuizEditor/ElementEditor/SheetMusicElementEditor";
import TextAnswersElementEditor from "./QuizEditor/ElementEditor/TextAnswersElementEditor";

export function QuizElementEditor({
  element,
  ...props
}: {
  element: QuizElement;
  quiz: Quiz;
  index: number;
}) {
  switch (element.type) {
    case QuizElementType.metronome:
      return <MetronomeElementEditor {...{ ...props, element }} />;
    case QuizElementType.remoteImage:
      return <RemoteImageElementEditor {...{ ...props, element }} />;
    case QuizElementType.remoteSound:
      return <RemoteSoundElementEditor {...{ ...props, element }} />;
    case QuizElementType.sheetMusic:
      return <SheetMusicElementEditor {...{ ...props, element }} />;
    case QuizElementType.saxophoneFingering:
      return <SaxophoneFingeringElementEditor {...{ ...props, element }} />;
    case QuizElementType.textAnswers:
      return <TextAnswersElementEditor {...{ ...props, element }} />;
    case QuizElementType.sheetMusicAnswers:
      return <SheetMusicAnswersElementEditor {...{ ...props, element }} />;
    case QuizElementType.saxophoneFingeringAnswers:
      return (
        <SaxophoneFingeringAnswersElementEditor {...{ ...props, element }} />
      );
    case QuizElementType.remoteImageAnswers:
      return <RemoteImageAnswersElementEditor {...{ ...props, element }} />;
    case QuizElementType.body:
      return <BodyElement {...{ ...props, element }} />;
    default:
      return <p>Element '{element["type"]}' is Unsupported</p>;
  }
}
