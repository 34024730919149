/* eslint-disable max-classes-per-file */
export interface Era {
  index: number;
  id: string;
  name: string;
  levels: Array<Level>;
}

export interface SongLesson {
  name: string;
  id: string;
  quizes: Array<Quiz>;
}

export interface SongLevel {
  name: string;
  lessons: Array<SongLesson>;
  id: string;
}

export interface Level {
  name: string;
  lessons: Array<Lesson>;
  id: string;
}

export interface Lesson {
  name: string;
  id: string;
  quizes: Array<Quiz>;
}

export enum QuestionStatus {
  None,
  Selected,
  Correct,
  Wrong,
}

export type SheetMeasure = {
  id: string;
  notes: Array<SheetNote>;
  newLine?: boolean;
};
export type SheetChord = {
  root?: NoteName;
  type: SheetChordType;
};

export enum SheetChordType {
  major = "major",
  minor = "major",
  majorSeventh = "major-seventh",
  minorSeventh = "minor-seventh",
  dominant = "dominant",
}

export const sheetCordTypes = [
  SheetChordType.major,
  SheetChordType.minor,
  SheetChordType.majorSeventh,
  SheetChordType.minorSeventh,
  SheetChordType.dominant,
];

export enum NoteDuration {
  whole = "whole",
  half = "half",
  quarter = "quarter",
  eighth = "eighth",
  sixteenth = "16th",
}

export const noteDurations = [
  NoteDuration.whole,
  NoteDuration.half,
  NoteDuration.quarter,
  NoteDuration.eighth,
  NoteDuration.sixteenth,
];

export enum Slur {
  startTop = "start-top",
  startBottom = "start-bottom",
  stop = "stop",
}

export enum Tie {
  startTop = "start-top",
  startBottom = "start-bottom",
  stop = "stop",
}

export enum Dot {
  single = "single",
  double = "double",
}

export type SheetNote = {
  noteName?: NoteName;
  duration: NoteDuration;
  label?: string;
  chord?: SheetChord;
  slur?: Slur;
  tie?: Tie;
  dot?: Dot;
};

export enum Clef {
  G = "G",
  F = "F",
}

export const clefs: Clef[] = [Clef.G, Clef.F];

export type Fifth =
  | -7
  | -6
  | -5
  | -4
  | -3
  | -2
  | -1
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7;
export const fifths: Fifth[] = [
  -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7,
];
export type Key = {
  fifths: Fifth;
};

export type SheetMusicContent = {
  clef: Clef;
  key?: Key;
  measures: Array<SheetMeasure>;
};

export type SaxophoneFingering = {
  noteName: SaxophoneNoteName;
  alternative?: number;
};

export enum QuizElementType {
  body = "body",
  metronome = "metronome",
  remoteImage = "remoteImage",
  remoteSound = "remoteSound",
  sheetMusic = "sheetMusic",
  saxophoneFingering = "saxophoneFingering",
  textAnswers = "textAnswers",
  remoteImageAnswers = "remoteImageAnswers",
  sheetMusicAnswers = "sheetMusicAnswers",
  saxophoneFingeringAnswers = "saxophoneFingeringAnswers",
}

export type BodyElement = {
  text: string;
};
export type MetronomeElement = { bpm: number; beats: number };
export type RemoteImageElement = { src: string };
export type RemoteSoundElement = { src: string };
export type SheetMusicElement = { content: SheetMusicContent };
export type SaxophoneFingeringElement = SaxophoneFingering;
export type TextAnswersElement = { answers: TextAnswer[] };
export type SheetMusicAnswerElement = { answers: SheetMusicAnswer[] };
export type RemoteImageAnswersElement = { answers: RemoteImageAnswer[] };
export type SaxophoneFingeringAnswersElement = {
  answers: SaxophoneFingeringAnswer[];
};

export function getElementAnswers(
  element: QuizElement,
): Answer<unknown>[] | undefined {
  switch (element.type) {
    case QuizElementType.textAnswers:
    case QuizElementType.sheetMusicAnswers:
    case QuizElementType.saxophoneFingeringAnswers:
    case QuizElementType.remoteImageAnswers:
      return element.answers;
    case QuizElementType.body:
    case QuizElementType.remoteImage:
    case QuizElementType.remoteSound:
    case QuizElementType.sheetMusic:
    case QuizElementType.saxophoneFingering:
      return undefined;
  }
}

export type QuizBodyElement = {
  id: string;
  type: QuizElementType.body;
} & BodyElement;
export type QuizMetronomeElement = {
  id: string;
  type: QuizElementType.metronome;
} & MetronomeElement;
export type QuizRemoteImageElement = {
  id: string;
  type: QuizElementType.remoteImage;
} & RemoteImageElement;
export type QuizRemoteSoundElement = {
  id: string;
  type: QuizElementType.remoteSound;
} & RemoteSoundElement;
export type QuizSheetMusicElement = {
  id: string;
  type: QuizElementType.sheetMusic;
} & SheetMusicElement;
export type QuizSaxophoneFingeringElement = {
  id: string;
  type: QuizElementType.saxophoneFingering;
} & SaxophoneFingeringElement;
export type QuizTextAnswersElement = {
  id: string;
  type: QuizElementType.textAnswers;
} & TextAnswersElement;
export type QuizSheetMusicAnswerElement = {
  id: string;
  type: QuizElementType.sheetMusicAnswers;
} & SheetMusicAnswerElement;
export type QuizSaxophoneFingeringAnswersElement = {
  id: string;
  type: QuizElementType.saxophoneFingeringAnswers;
} & SaxophoneFingeringAnswersElement;
export type QuizRemoteImageAnswersElement = {
  id: string;
  type: QuizElementType.remoteImageAnswers;
} & RemoteImageAnswersElement;

export type QuizElement =
  | QuizBodyElement
  | QuizMetronomeElement
  | QuizRemoteImageElement
  | QuizRemoteSoundElement
  | QuizSheetMusicElement
  | QuizSaxophoneFingeringElement
  | QuizTextAnswersElement
  | QuizSheetMusicAnswerElement
  | QuizSaxophoneFingeringAnswersElement
  | QuizRemoteImageAnswersElement;

export type Quiz = {
  id: string;
  title: string;
  elements: QuizElement[];
};
export type SheetMusicAnswer = Answer<SheetMusicContent>;
export type SaxophoneFingeringAnswer = Answer<SaxophoneFingering>;
export type RemoteImageAnswer = Answer<{ src: string }>;
export type TextAnswer = Answer<string>;

export type Answer<T> = {
  content: T;
  value: boolean;
};
export type SaxophoneButton =
  | "octave"
  | "leftPalmTop"
  | "leftPalmMiddle"
  | "leftPalmBottom"
  | "frontF"
  | "B"
  | "Bb"
  | "A"
  | "G"
  | "leftPinkyTop"
  | "leftPinkyLeft"
  | "leftPinkyRight"
  | "leftPinkyBottom"
  | "F"
  | "E"
  | "D"
  | "rightPalmTop"
  | "rightPalmMiddle"
  | "rightPalmBottom"
  | "highF"
  | "sideF"
  | "rightPinkyTop"
  | "rightPinkyBottom";

export type BasicNoteName = "C" | "D" | "E" | "F" | "G" | "A" | "B";
export const basicNoteNames: BasicNoteName[] = [
  "C",
  "D",
  "E",
  "F",
  "G",
  "A",
  "B",
];
type OctavesNote<T extends number> =
  | `C${T}b`
  | `C${T}`
  | `C${T}#`
  | `D${T}b`
  | `D${T}`
  | `D${T}#`
  | `E${T}b`
  | `E${T}`
  | `E${T}#`
  | `F${T}b`
  | `F${T}`
  | `F${T}#`
  | `G${T}b`
  | `G${T}`
  | `G${T}#`
  | `A${T}b`
  | `A${T}`
  | `A${T}#`
  | `B${T}b`
  | `B${T}`
  | `B${T}#`;

function getOctavesNotes<T extends number>(octave: T): OctavesNote<T>[] {
  return [
    `C${octave}b`,
    `C${octave}`,
    `C${octave}#`,
    `D${octave}b`,
    `D${octave}`,
    `D${octave}#`,
    `E${octave}b`,
    `E${octave}`,
    `E${octave}#`,
    `F${octave}b`,
    `F${octave}`,
    `F${octave}#`,
    `G${octave}b`,
    `G${octave}`,
    `G${octave}#`,
    `A${octave}b`,
    `A${octave}`,
    `A${octave}#`,
    `B${octave}b`,
    `B${octave}`,
    `B${octave}#`,
  ];
}

export type NoteName =
  | OctavesNote<1>
  | OctavesNote<2>
  | OctavesNote<3>
  | OctavesNote<4>
  | OctavesNote<5>
  | OctavesNote<6>;

export type SaxophoneNoteName =
  | "B3b"
  | "B3"
  | "B3#"
  | OctavesNote<4>
  | OctavesNote<5>
  | "C6b"
  | "C6"
  | "C6#"
  | "D6b"
  | "D6"
  | "D6#"
  | "E6b"
  | "E6"
  | "E6#"
  | "F6b"
  | "F6"
  | "F6#";

export const saxophoneNoteNames: SaxophoneNoteName[] = [
  "B3b",
  "B3",
  "B3#",
  ...getOctavesNotes(4),
  ...getOctavesNotes(5),
  "C6b",
  "C6",
  "C6#",
  "D6b",
  "D6",
  "D6#",
  "E6b",
  "E6",
  "E6#",
  "F6b",
  "F6",
  "F6#",
];
export type FingeringsType = {
  [key in SaxophoneNoteName]: Array<Array<SaxophoneButton>>;
};
export const fingerings: FingeringsType = {
  B3b: [
    ["B", "A", "G", "F", "E", "D", "rightPinkyBottom", "leftPinkyBottom"],
    ["B", "Bb", "A", "G", "F", "E", "D", "rightPinkyBottom", "leftPinkyBottom"],
  ],
  B3: [
    ["B", "A", "G", "F", "E", "D", "rightPinkyBottom", "leftPinkyRight"],
    ["B", "Bb", "A", "G", "F", "E", "D", "rightPinkyBottom", "leftPinkyRight"],
  ],
  "B3#": [
    ["B", "A", "G", "F", "E", "D", "rightPinkyBottom"],
    ["B", "Bb", "A", "G", "F", "E", "D", "rightPinkyBottom"],
  ],
  C4b: [
    ["B", "A", "G", "F", "E", "D", "rightPinkyBottom", "leftPinkyRight"],
    ["B", "Bb", "A", "G", "F", "E", "D", "rightPinkyBottom", "leftPinkyRight"],
  ],
  C4: [
    ["B", "A", "G", "F", "E", "D", "rightPinkyBottom"],
    ["B", "Bb", "A", "G", "F", "E", "D", "rightPinkyBottom"],
  ],
  "C4#": [
    ["B", "A", "G", "F", "E", "D", "rightPinkyBottom", "leftPinkyLeft"],
    ["B", "Bb", "A", "G", "F", "E", "D", "rightPinkyBottom", "leftPinkyLeft"],
  ],
  D4b: [
    ["B", "A", "G", "F", "E", "D", "rightPinkyBottom", "leftPinkyLeft"],
    ["B", "Bb", "A", "G", "F", "E", "D", "rightPinkyBottom", "leftPinkyLeft"],
  ],
  D4: [
    ["B", "A", "G", "F", "E", "D"],
    ["B", "Bb", "A", "G", "F", "E", "D"],
  ],
  "D4#": [
    ["B", "A", "G", "F", "E", "D", "rightPinkyTop"],
    ["B", "Bb", "A", "G", "F", "E", "D", "rightPinkyTop"],
  ],
  E4b: [
    ["B", "A", "G", "F", "E", "D", "rightPinkyTop"],
    ["B", "Bb", "A", "G", "F", "E", "D", "rightPinkyTop"],
  ],
  E4: [
    ["B", "A", "G", "F", "E"],
    ["B", "Bb", "A", "G", "F", "E"],
  ],
  "E4#": [
    ["B", "A", "G", "F"],
    ["B", "Bb", "A", "G", "F"],
  ],
  F4b: [
    ["B", "A", "G", "F", "E"],
    ["B", "Bb", "A", "G", "F", "E"],
  ],
  F4: [
    ["B", "A", "G", "F"],
    ["B", "Bb", "A", "G", "F"],
  ],
  "F4#": [
    ["B", "A", "G", "E"],
    ["B", "Bb", "A", "G", "E"],
    ["B", "A", "G", "F", "sideF"],
    ["B", "Bb", "A", "G", "F", "sideF"],
  ],
  G4b: [
    ["B", "A", "G", "E"],
    ["B", "Bb", "A", "G", "E"],
    ["B", "A", "G", "F", "sideF"],
    ["B", "Bb", "A", "G", "F", "sideF"],
  ],
  G4: [
    ["B", "A", "G"],
    ["B", "Bb", "A", "G"],
  ],
  "G4#": [
    ["B", "A", "G", "leftPinkyTop"],
    ["B", "Bb", "A", "G", "leftPinkyTop"],
    ["B", "A", "G", "leftPinkyBottom"],
    ["B", "A", "G", "leftPinkyLeft"],
    ["B", "A", "G", "leftPinkyRight"],
  ],
  A4b: [
    ["B", "A", "G", "leftPinkyTop"],
    ["B", "Bb", "A", "G", "leftPinkyTop"],
    ["B", "A", "G", "leftPinkyBottom"],
    ["B", "A", "G", "leftPinkyLeft"],
    ["B", "A", "G", "leftPinkyRight"],
  ],
  A4: [
    ["B", "A"],
    ["B", "Bb", "A"],
  ],
  "A4#": [
    ["B", "Bb"],
    ["B", "A", "rightPalmBottom"],
    ["B", "F"],
    ["B", "E"],
    ["B", "D"],
    ["B", "F", "E", "D"],
    ["B", "F", "E"],
    ["B", "F", "D"],
    ["B", "E", "D"],
  ],
  B4b: [
    ["B", "Bb"],
    ["B", "A", "rightPalmBottom"],
    ["B", "F"],
    ["B", "E"],
    ["B", "D"],
  ],
  B4: [["B"]],
  "B4#": [["A"], ["B", "rightPalmMiddle"]],
  C5b: [["B"]],
  C5: [["A"], ["B", "rightPalmMiddle"]],
  "C5#": [[]],
  D5b: [[]],
  D5: [
    ["octave", "B", "A", "G", "F", "E", "D"],
    ["octave", "B", "Bb", "A", "G", "F", "E", "D"],
  ],
  "D5#": [
    ["octave", "B", "A", "G", "F", "E", "D", "rightPinkyTop"],
    ["octave", "B", "Bb", "A", "G", "F", "E", "D", "rightPinkyTop"],
  ],
  E5b: [
    ["octave", "B", "A", "G", "F", "E", "D", "rightPinkyTop"],
    ["octave", "B", "Bb", "A", "G", "F", "E", "D", "rightPinkyTop"],
  ],
  E5: [
    ["octave", "B", "A", "G", "F", "E"],
    ["octave", "B", "Bb", "A", "G", "F", "E"],
  ],
  "E5#": [
    ["octave", "B", "A", "G", "F"],
    ["octave", "B", "Bb", "A", "G", "F"],
  ],
  F5b: [
    ["octave", "B", "A", "G", "F", "E"],
    ["octave", "B", "Bb", "A", "G", "F", "E"],
  ],
  F5: [
    ["octave", "B", "A", "G", "F"],
    ["octave", "B", "Bb", "A", "G", "F"],
  ],

  "F5#": [
    ["B", "A", "G", "E"],
    ["B", "Bb", "A", "G", "E"],
    ["B", "A", "G", "F", "sideF"],
    ["B", "Bb", "A", "G", "F", "sideF"],
  ],
  G5b: [
    ["B", "A", "G", "E"],
    ["B", "Bb", "A", "G", "E"],
    ["B", "A", "G", "F", "sideF"],
    ["B", "Bb", "A", "G", "F", "sideF"],
  ],
  G5: [
    ["octave", "B", "A", "G"],
    ["octave", "B", "Bb", "A", "G"],
  ],
  "G5#": [
    ["octave", "B", "A", "G", "leftPinkyTop"],
    ["octave", "B", "Bb", "A", "G", "leftPinkyTop"],
    ["octave", "B", "A", "G", "leftPinkyBottom"],
    ["octave", "B", "A", "G", "leftPinkyLeft"],
    ["octave", "B", "A", "G", "leftPinkyRight"],
  ],
  A5b: [
    ["octave", "B", "A", "G", "leftPinkyTop"],
    ["octave", "B", "Bb", "A", "G", "leftPinkyTop"],
    ["octave", "B", "A", "G", "leftPinkyBottom"],
    ["octave", "B", "A", "G", "leftPinkyLeft"],
    ["octave", "B", "A", "G", "leftPinkyRight"],
  ],
  A5: [
    ["octave", "B", "A"],
    ["octave", "B", "Bb", "A"],
  ],
  "A5#": [
    ["octave", "B", "Bb"],
    ["octave", "B", "A", "rightPalmBottom"],
    ["octave", "B", "F"],
    ["octave", "B", "E"],
    ["octave", "B", "D"],
    ["octave", "B", "F", "E", "D"],
    ["octave", "B", "F", "E"],
    ["octave", "B", "F", "D"],
    ["octave", "B", "E", "D"],
  ],
  B5b: [
    ["octave", "B", "Bb"],
    ["octave", "B", "A", "rightPalmBottom"],
    ["octave", "B", "F"],
    ["octave", "B", "E"],
    ["octave", "B", "D"],
  ],
  B5: [["octave", "B"]],
  "B5#": [
    ["octave", "A"],
    ["octave", "B", "rightPalmMiddle"],
  ],
  C6b: [
    ["octave", "B"],
    ["octave", "B", "rightPalmMiddle"],
  ],
  C6: [
    ["octave", "A"],
    ["octave", "B", "rightPalmMiddle"],
  ],
  "C6#": [["octave"]],
  D6b: [["octave"]],
  D6: [["octave", "leftPalmTop"]],
  "D6#": [["octave", "leftPalmTop", "leftPalmMiddle"]],
  E6b: [["octave", "leftPalmTop", "leftPalmMiddle"]],
  E6: [
    ["octave", "leftPalmTop", "leftPalmMiddle", "rightPalmTop"],
    ["octave", "frontF", "A", "G"],
  ],
  "E6#": [
    [
      "octave",
      "leftPalmTop",
      "leftPalmMiddle",
      "leftPalmBottom",
      "rightPalmTop",
    ],
    ["octave", "frontF", "A"],
  ],
  F6b: [
    [
      "octave",
      "leftPalmTop",
      "leftPalmMiddle",
      "leftPalmBottom",
      "rightPalmTop",
    ],
    ["octave", "frontF", "A"],
  ],
  F6: [
    [
      "octave",
      "leftPalmTop",
      "leftPalmMiddle",
      "leftPalmBottom",
      "rightPalmTop",
    ],
    ["octave", "frontF", "A"],
  ],
  "F6#": [["octave", "frontF", "A", "rightPalmBottom"]],
};
export const saxophoneNaturalNotes: Array<SaxophoneNoteName> =
  saxophoneNoteNames.filter(
    (note) => !note.includes("#") && !note.includes("b"),
  );
// C2 => F4
export const bassNoteNames: Array<NoteName> = [
  ...getOctavesNotes(2),
  ...getOctavesNotes(3),
  `C4`,
  `C4#`,
  `D4b`,
  `D4`,
  `D4#`,
  `E4b`,
  `E4`,
  `E4#`,
  `F4b`,
  `F4`,
];
export const bassNaturalNotes: Array<NoteName> = bassNoteNames.filter(
  (note) => !note.includes("#") && !note.includes("b"),
);

export interface Chord {
  name: string;
  shortName: string;
  notes: SaxophoneNoteName[];
}

export const chords: { [key: string]: Chord } = {
  AMajor7: {
    name: "A Major 7th",
    shortName: "AΔ7",
    notes: ["A4", "C5#", "E5", "G5#"],
  },
  "C#7": {
    name: "C# Dominant 7th",
    shortName: "C#7",
    notes: ["C5#", "E5#", "G5#", "B5"],
  },
};
