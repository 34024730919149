import React, { createContext, useContext } from "react";
import useLocalState from "../hooks/useLocalState";

const OpenContext = createContext<{
  isOpen: (s: string) => boolean;
  setOpen: (s: string) => void;
  setAllOpen: (s: string[]) => void;
  toggle: (s: string) => void;
}>({
  isOpen: () => false,
  setOpen: () => {},
  setAllOpen: () => {},
  toggle: () => {},
});
export const OpenContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [openIds, setOpenIds] = useLocalState<string[]>("open-context", []);
  return (
    <OpenContext.Provider
      value={{
        isOpen: (id) => openIds.includes(id),
        setOpen: (id) => setOpenIds([...openIds, id]),
        setAllOpen: (ss) => setOpenIds(ss),
        toggle: (id) => {
          if (openIds.includes(id)) {
            setOpenIds([...openIds.filter((i) => i !== id)]);
          } else {
            setOpenIds([...openIds, id]);
          }
        },
      }}
    >
      {children}
    </OpenContext.Provider>
  );
};
export const useOpenContext = () => useContext(OpenContext);
