import React from "react";
import { Icon, Menu, MenuItem, MenuItemProps } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";

function MoreButton({
  actions,
  size,
}: {
  actions?: MenuItemProps[];
  size?: number;
}) {
  return (
    <Popover2
      placement={"bottom-end"}
      minimal
      content={
        <Menu>
          {actions && (
            <>
              {actions.map((action, index) => (
                <MenuItem {...{ ...action, key: index }} />
              ))}
            </>
          )}
        </Menu>
      }
    >
      <Icon icon={"more"} color={"grey"} size={size} />
    </Popover2>
  );
}

export default MoreButton;
