import React from "react";
import { EditableText } from "@blueprintjs/core";
import { useConfiguration } from "../../../../contexts/ConfigurationContext";

function SrcUpload({
  setSrc,
  src,
  resourceType,
}: {
  setSrc: (src: string) => void;
  resourceType: string;
  src: string;
}) {
  const {
    configuration: { deviceWidth },
  } = useConfiguration();

  return (
    <div style={{ maxWidth: deviceWidth - 20, flex: 1 }}>
      <EditableText
        placeholder={"File name of " + resourceType}
        onConfirm={setSrc}
        defaultValue={src}
      />
    </div>
  );
}

export default SrcUpload;
