import { Era, QuizElementType } from "../GameElements";

export type Warning = {
  message: string;
  eraId: string;
  levelId?: string;
  lessonId?: string;
  quizId?: string;
};

export function mapToWarnings(
  eras: Era[] | undefined,
  soundExists: (src: string) => boolean,
  imageExists: (src: string) => boolean,
) {
  const warnings: Warning[] = [];
  eras?.forEach((era) => {
    if (!era.name) {
      warnings.push({ message: "Empty era title", eraId: era.id });
    }
    if (era.levels.length === 0) {
      warnings.push({ message: "Era has no levels", eraId: era.id });
    }
    era.levels.forEach((level) => {
      if (!level.name) {
        warnings.push({
          message: "Empty level title",
          eraId: era.id,
          levelId: level.id,
        });
      }
      if (level.lessons.length === 0) {
        warnings.push({
          message: "Level has no lessons",
          eraId: era.id,
          levelId: level.id,
        });
      }
      level.lessons.forEach((lesson) => {
        if (!lesson.name) {
          warnings.push({
            message: "Empty lesson title",
            eraId: era.id,
            levelId: level.id,
            lessonId: lesson.id,
          });
        }
        if (lesson.quizes.length === 0) {
          warnings.push({
            message: "Era has no quizes",
            eraId: era.id,
            levelId: level.id,
            lessonId: lesson.id,
          });
        }
        lesson.quizes.forEach((quiz) => {
          if (!quiz.title) {
            warnings.push({
              message: "Empty quiz title",
              eraId: era.id,
              levelId: level.id,
              lessonId: lesson.id,
              quizId: quiz.id,
            });
          }
          if (quiz.elements.length === 0) {
            warnings.push({
              message: "Quiz has no elements",
              eraId: era.id,
              levelId: level.id,
              lessonId: lesson.id,
              quizId: quiz.id,
            });
          }
          quiz.elements.forEach(async (element) => {
            switch (element.type) {
              case QuizElementType.remoteImage:
                if (!element.src) {
                  warnings.push({
                    message: "Empty image",
                    eraId: era.id,
                    levelId: level.id,
                    lessonId: lesson.id,
                    quizId: quiz.id,
                  });
                } else {
                  if (!imageExists(element.src)) {
                    warnings.push({
                      message: "Image url invalid '" + element.src + "'",
                      eraId: era.id,
                      levelId: level.id,
                      lessonId: lesson.id,
                      quizId: quiz.id,
                    });
                  }
                }
                break;
              case QuizElementType.remoteSound:
                if (!element.src) {
                  warnings.push({
                    message: "Empty sound",
                    eraId: era.id,
                    levelId: level.id,
                    lessonId: lesson.id,
                    quizId: quiz.id,
                  });
                } else {
                  if (!soundExists(element.src)) {
                    warnings.push({
                      message: "Sound url invalid '" + element.src + "'",
                      eraId: era.id,
                      levelId: level.id,
                      lessonId: lesson.id,
                      quizId: quiz.id,
                    });
                  }
                }
                break;
              case QuizElementType.sheetMusic:
                if (element.content.measures.length === 0)
                  warnings.push({
                    message: "Empty sheet music",
                    eraId: era.id,
                    levelId: level.id,
                    lessonId: lesson.id,
                    quizId: quiz.id,
                  });
                break;
              case QuizElementType.saxophoneFingering:
                break;
              case QuizElementType.textAnswers:
                if (element.answers.length < 2)
                  warnings.push({
                    message: "Less than 2 text answers",
                    eraId: era.id,
                    levelId: level.id,
                    lessonId: lesson.id,
                    quizId: quiz.id,
                  });
                if (
                  element.answers.filter((answer) => answer.value).length !== 1
                ) {
                  warnings.push({
                    message: "Multiple or no correct text answers",
                    eraId: era.id,
                    levelId: level.id,
                    lessonId: lesson.id,
                    quizId: quiz.id,
                  });
                }
                if (
                  element.answers.filter((answer) => !answer.content).length !==
                  0
                ) {
                  warnings.push({
                    message: "Empty text answers",
                    eraId: era.id,
                    levelId: level.id,
                    lessonId: lesson.id,
                    quizId: quiz.id,
                  });
                }
                break;
              case QuizElementType.sheetMusicAnswers:
                break;
              case QuizElementType.saxophoneFingeringAnswers:
                if (element.answers.length < 2)
                  warnings.push({
                    message: "Less than 2 sax answers",
                    eraId: era.id,
                    levelId: level.id,
                    lessonId: lesson.id,
                    quizId: quiz.id,
                  });
                if (
                  element.answers.filter((answer) => answer.value).length !== 1
                ) {
                  warnings.push({
                    message: "Multiple or no correct sax answers",
                    eraId: era.id,
                    levelId: level.id,
                    lessonId: lesson.id,
                    quizId: quiz.id,
                  });
                }
                break;
              case QuizElementType.remoteImageAnswers:
                if (element.answers.length < 2)
                  warnings.push({
                    message: "Less than 2 image answers",
                    eraId: era.id,
                    levelId: level.id,
                    lessonId: lesson.id,
                    quizId: quiz.id,
                  });
                if (
                  element.answers.filter((answer) => answer.value).length !== 1
                ) {
                  warnings.push({
                    message: "Multiple or no correct image answers",
                    eraId: era.id,
                    levelId: level.id,
                    lessonId: lesson.id,
                    quizId: quiz.id,
                  });
                }
                if (
                  element.answers.filter((answer) => answer.content.src)
                    .length !== 0
                ) {
                  warnings.push({
                    message: "Empty image answers",
                    eraId: era.id,
                    levelId: level.id,
                    lessonId: lesson.id,
                    quizId: quiz.id,
                  });
                }
                break;
              case QuizElementType.body:
                if (!element.text) {
                  warnings.push({
                    message: "Empty body",
                    eraId: era.id,
                    levelId: level.id,
                    lessonId: lesson.id,
                    quizId: quiz.id,
                  });
                }
                break;
              default:
            }
          });
        });
      });
    });
  });
  return warnings;
}
