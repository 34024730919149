import React from "react";
import { Spinner } from "@blueprintjs/core";

function LoadingScreen() {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Spinner size={50} />{" "}
    </div>
  );
}

export default LoadingScreen;
