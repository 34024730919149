export type MessageData =
  | { id?: string; loaded: true; aspectRatio: number }
  | { id?: string; loaded: false; error: any };
const create = (musicXml: string, id?: string) => `
<html lang="en"
>
<head>
	<style>
	html{
	/*background: aqua;*/
	}
	</style>
	<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=1.0" />
	<title></title>

</head>

<body style="overflow: clip">
	<div id="osmdCanvas" style="overflow: clip" />
	<script src="https://unpkg.com/opensheetmusicdisplay@1.4.5/build/opensheetmusicdisplay.min.js"></script>
	<script>
		function myalert(mess) {
			window.ReactNativeWebView?.postMessage(JSON.stringify(mess));
			window?.top?.postMessage(mess);
		}
		function loadMusicxml() {
			try {
				const openSheetMusicDisplay = new opensheetmusicdisplay.OpenSheetMusicDisplay("osmdCanvas", {
					drawFromMeasureNumber: 1,
					drawUpToMeasureNumber: Number.MAX_SAFE_INTEGER, // draw all measures, up to the end of the sample
					drawPartNames: false,
					drawMetronomeMarks: false,
					drawLyrics: true,
					drawingParameters: "compacttight",
					newSystemFromXML: true,
					autoGenerateMultipleRestMeasuresFromRestMeasures: false
				});
				openSheetMusicDisplay
					.load(\`${musicXml}\`)
					.then(
						function () {
							openSheetMusicDisplay;
							window.osmd = openSheetMusicDisplay; // give access to osmd object in Browser console, e.g. for osmd.setOptions()
							openSheetMusicDisplay.EngravingRules.PageLeftMargin = 1;
							openSheetMusicDisplay.EngravingRules.PageRightMargin = 1;
							openSheetMusicDisplay.EngravingRules.BetweenStaffDistance = 1;
							openSheetMusicDisplay.EngravingRules.TitleTopDistance = 1;
							openSheetMusicDisplay.EngravingRules.CompactMode = true;
							openSheetMusicDisplay.EngravingRules.StretchLastSystemLine = true;
							openSheetMusicDisplay.EngravingRules.RenderMeasureNumbers = false;
							openSheetMusicDisplay.render();
							// osmd.cursor.show(); // this would show the cursor on the first note
							// osmd.cursor.next(); // advance the cursor one note
							myalert({
									    id:"${id}",
										loaded: true,
										aspectRatio :document.getElementById("osmdCanvas").clientWidth/document.getElementById("osmdCanvas").clientHeight,
									});
						},
					).catch((e) => {
						myalert({loaded:false,error:e,id:"${id}"});
					});

			}
			catch (e) {
					myalert({loaded:false,error:e,id:"${id}"});
			}
			finally { }
		}
        loadMusicxml();
	</script>
</body>

</html>
`;
export default create;
