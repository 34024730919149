import React from "react";
import { useConfiguration } from "../../../../contexts/ConfigurationContext";
import { ImageList } from "../ImageList";
import SrcUpload from "./SrcUpload";

function RemoteImageEditor({
  src,
  setSrc,
}: {
  src: string;
  setSrc: (s: string) => void;
}) {
  const {
    configuration: { imageServer },
  } = useConfiguration();
  return (
    <div>
      <img
        src={imageServer + "/" + src}
        style={{ maxWidth: "100%" }}
        alt={src}
      />
      <div style={{ display: "flex" }}>
        <SrcUpload resourceType={"image"} src={src} setSrc={setSrc} />
        <ImageList onSubmit={setSrc} />
      </div>
    </div>
  );
}

export default RemoteImageEditor;
