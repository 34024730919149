import React, { useState } from "react";
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
import {
  Button,
  Classes,
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
  Navbar,
  NavbarHeading,
} from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { useEras } from "../contexts/ErasContext";
import ConfigurationDialog from "../ConfigurationDialog";
import { firebaseApp } from "../firebase";
import { useDialogState } from "./DialogProps";
import { WarningsIcon } from "./WarningsIcon";
import DiffDialog from "./editors/DiffDialog";
import UploadDialog from "./editors/UploadDialog";
import { getAuth } from "firebase/auth";

const Logo = () => (
  <svg
    width="28"
    height="31"
    viewBox="0 0 28 31"
    stroke={"#4E6969"}
    fill={"none"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9204 12.9632C10.9204 12.8238 11.32 16.106 11.5968 18.3953C11.7477 19.6443 12.488 20.5938 13.7423 20.6912V20.6912C15.1712 20.8021 16.6106 19.7447 16.6497 18.312L16.9731 6.46757L24.1084 1.44824L26.6181 4.40079L21.4019 8.48515C21.4019 10.5191 21.4019 15.4728 21.4019 20.8859C21.4019 26.2989 16.9731 29.4482 12.7903 29.4482V29.4482C7.3732 29.4482 2.89392 25.2279 2.57177 19.8203L2.45642 17.8841"
      strokeWidth="1.57469"
      strokeLinejoin="round"
    />
    <path
      d="M10.8702 12.5468C11.0223 12.7664 11.0829 13.3162 10.5272 14.2679C10.007 15.1587 9.06566 16.1647 7.808 17.0359C6.55033 17.9072 5.27766 18.4349 4.26099 18.6089C3.17463 18.7947 2.68119 18.5447 2.52911 18.3251C2.37703 18.1056 2.31638 17.5558 2.87215 16.604C3.39226 15.7133 4.33365 14.7073 5.59131 13.8361C6.84897 12.9648 8.12164 12.437 9.13832 12.2631C10.2247 12.0773 10.7181 12.3273 10.8702 12.5468Z"
      strokeWidth="1.57469"
    />
    <path
      d="M8.73161 5.98605C9.25781 6.74562 9.06862 7.78795 8.30905 8.31414C7.54947 8.84034 6.50715 8.65115 5.98095 7.89157C5.45476 7.132 5.64395 6.08967 6.40352 5.56348C7.16309 5.03728 8.20542 5.22647 8.73161 5.98605Z"
      strokeWidth="1.57469"
    />
    <ellipse
      rx="0.787345"
      ry="0.787346"
      transform="matrix(0.822021 -0.569457 0.569456 0.822022 9.75231 3.72522)"
      fill="none"
    />
    <line
      x1="14.2666"
      y1="12.1755"
      x2="18.597"
      y2="12.1755"
      strokeWidth="1.57469"
      strokeLinecap="round"
    />
    <line
      x1="14.2666"
      y1="15.1287"
      x2="18.597"
      y2="15.1287"
      strokeWidth="1.57469"
      strokeLinecap="round"
    />
  </svg>
);

function TopBar() {
  const {
    eras,
    setEras,
    hasUnpublishedChanges,
    publishChanges,
    isPublishingChanges,
  } = useEras();
  const [signOut, loading] = useSignOut(getAuth(firebaseApp));
  const [user] = useAuthState(getAuth(firebaseApp));
  const [isDiffOpen, setIsDiffOpen] = useState(false);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const { dialogProps, open } = useDialogState();

  function download() {
    const content = JSON.stringify({ eras }, null, 2);
    const a = document.createElement("a");
    const file = new Blob([content], { type: "text/plain" });
    a.href = URL.createObjectURL(file);
    a.download = "eras.json";
    a.click();
    setEras(undefined);
  }

  return (
    <>
      <Navbar fixedToTop>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <NavbarHeading>
            <Logo />
          </NavbarHeading>
          <Navbar.Group align={"center"}>
            {user && (
              <>
                <Navbar.Divider />
                <Navbar.Group align={"right"}>
                  <Button
                    text={"Publish"}
                    loading={isPublishingChanges}
                    icon={"upload"}
                    disabled={!hasUnpublishedChanges}
                    onClick={publishChanges}
                  />
                  <Navbar.Divider />
                  <Button
                    icon={"changes"}
                    disabled={!hasUnpublishedChanges}
                    onClick={() => setIsDiffOpen(true)}
                  />
                  <Navbar.Divider />
                  <Popover2
                    placement={"bottom-end"}
                    minimal
                    content={
                      <Menu>
                        <MenuItem
                          className={Classes.MINIMAL}
                          onClick={download}
                          icon="download"
                          text="Download"
                        />
                        <MenuItem
                          className={Classes.MINIMAL}
                          icon="document"
                          text="Open file"
                          onClick={() => setIsUploadDialogOpen(true)}
                        />
                        <MenuDivider />
                        <MenuItem
                          icon={"cog"}
                          text={"Settings"}
                          onClick={open}
                        />
                        <MenuItem
                          icon={"log-out"}
                          text="Logout"
                          onClick={signOut}
                          disabled={loading}
                        />
                      </Menu>
                    }
                  >
                    <Icon icon={"more"} />
                  </Popover2>
                  <Navbar.Divider />
                  <WarningsIcon />
                </Navbar.Group>
              </>
            )}
          </Navbar.Group>
        </div>
      </Navbar>
      <UploadDialog
        isOpen={isUploadDialogOpen}
        onClose={() => setIsUploadDialogOpen(false)}
      />
      <DiffDialog isOpen={isDiffOpen} onClose={() => setIsDiffOpen(false)} />
      <ConfigurationDialog {...dialogProps} />
    </>
  );
}

export default TopBar;
