import React from "react";
import { useConfiguration } from "../../../../contexts/ConfigurationContext";
import { useEras } from "../../../../contexts/ErasContext";
import { QuizRemoteSoundElement } from "../../../../GameElements";
import { SoundList } from "../SoundList";
import { ElementEditorProps } from "./ElementEditorProps";
import SrcUpload from "./SrcUpload";
import {
  TopRightActionsButton,
  useElementActions,
} from "./TopRightActionsButton";

function RemoteSoundElementEditor(
  props: ElementEditorProps<QuizRemoteSoundElement>,
) {
  const { update } = useEras();
  const {
    configuration: { soundServer },
  } = useConfiguration();
  const actions = useElementActions(props);

  return (
    <TopRightActionsButton key={props.element.src} {...{ actions }}>
      {props.element.src && (
        <audio style={{ width: "100%" }} controls>
          <source
            src={soundServer + "/" + props.element.src}
            type="audio/ogg"
          />
          Your browser does not support the audio props.element.
        </audio>
      )}
      <div style={{ display: "flex" }}>
        <SrcUpload
          setSrc={(src) => {
            props.element.src = src;
            update();
          }}
          resourceType={"sound"}
          src={props.element.src}
        />
        <SoundList
          onSubmit={(src) => {
            props.element.src = src;
            update();
          }}
        />
      </div>
    </TopRightActionsButton>
  );
}

export default RemoteSoundElementEditor;
