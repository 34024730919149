import React from "react";
import { Button, Classes, Icon, Menu, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { useConfiguration } from "../contexts/ConfigurationContext";
import { useEras } from "../contexts/ErasContext";
import { useOpenContext } from "../contexts/OpenContext";
import { mapToWarnings } from "../hooks/mapToWarnings";
import { useListImages, useListSounds } from "../hooks/useListFiles";

const useWarnings = () => {
  const { eras } = useEras();
  const { configuration } = useConfiguration();
  const images = useListImages();
  const sounds = useListSounds();

  return mapToWarnings(
    eras,
    (src) =>
      sounds.some(({ url }) => url === configuration.soundServer + "/" + src),
    (src) =>
      images.some(({ url }) => url === configuration.imageServer + "/" + src),
  );
};

export function WarningsIcon() {
  const warnings = useWarnings();
  const { setAllOpen } = useOpenContext();
  return (
    <>
      <Popover2
        placement={"bottom-end"}
        minimal
        disabled={!warnings.length}
        content={
          <Menu>
            {warnings.map((warning, index) => (
              <MenuItem
                key={index}
                className={Classes.MINIMAL}
                onClick={() => {
                  setAllOpen([
                    warning.eraId,
                    warning.lessonId ?? "",
                    warning.levelId ?? "",
                    warning.quizId ?? "",
                  ]);
                }}
                icon="warning-sign"
                text={warning.message}
              />
            ))}
          </Menu>
        }
      >
        {warnings.length ? (
          <Button rightIcon={<Icon icon={"warning-sign"} intent={"warning"} />}>
            {warnings.length}
          </Button>
        ) : (
          <Icon icon={"tick"} intent={"success"} />
        )}
      </Popover2>
    </>
  );
}
