import React, { ChangeEvent } from "react";
import { Dialog, FileInput } from "@blueprintjs/core";
import { useEras } from "../../contexts/ErasContext";
import { DialogProps } from "../DialogProps";

function UploadDialog({ isOpen, onClose }: DialogProps) {
  const { setEras } = useEras();

  function onFileUpload(file: File | null) {
    if (!file) return;
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      fileReader.result &&
        setEras(JSON.parse(fileReader.result.toString()).eras);
      onClose();
    };
    fileReader.readAsText(file);
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title={"Select eras.json"}>
      <FileInput
        title={"Eras.json"}
        inputProps={{
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            onFileUpload(e?.target?.files && e.target.files[0]);
          },
          accept: ".json",
        }}
      />
    </Dialog>
  );
}

export default UploadDialog;
