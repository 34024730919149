import React from "react";
import { EditableText, H2, H4, Icon, Intent, Tag } from "@blueprintjs/core";
import { useConfirmDialog } from "../../contexts/ConfirmDialogContext";
import { useEras } from "../../contexts/ErasContext";
import { useOpenContext } from "../../contexts/OpenContext";
import { duplicate, newLesson } from "../../utils";
import { Era, Level } from "../../GameElements";
import LessonEditor from "./LessonEditor";
import MoreButton from "./QuizEditor/MoreButton";

type LevelEditorProps = {
  level: Level;
  era: Era;
  index: number;
};

function LevelEditor({ level, era, index }: LevelEditorProps) {
  const { update } = useEras();
  const { isOpen, toggle } = useOpenContext();
  const moveUp = () => {
    era.levels.splice(index, 1);
    era.levels.splice(index - 1, 0, level); // 2nd parameter means remove one item only
    update();
  };
  const moveDown = () => {
    era.levels.splice(index, 1);
    era.levels.splice(index + 1, 0, level);
    update();
  };
  const { openConfirmDialog } = useConfirmDialog();
  const deleteIt = () => {
    openConfirmDialog({
      onSubmit: () => {
        era.levels.splice(index, 1);
        update();
      },
    });
  };
  const duplicateLevel = () => {
    const newElement = duplicate(level);
    era.levels.splice(index + 1, 0, newElement);
    update();
  };
  return (
    <div style={{ gap: 16 }}>
      <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
        <Icon
          style={{ alignSelf: "baseline" }}
          onClick={() => toggle(level.id)}
          size={22}
          icon={isOpen(level.id) ? "chevron-down" : "chevron-right"}
        />
        <H2>
          <EditableText
            placeholder={"Level name..."}
            onConfirm={(name) => {
              level.name = name;
              update();
            }}
            defaultValue={level.name}
          />
        </H2>
        <Tag minimal large round>
          <span>
            <b>{level.lessons.length}</b> lessons
          </span>
        </Tag>
        <MoreButton
          size={22}
          actions={[
            {
              icon: "arrow-up",
              disabled: index === 0,
              onClick: moveUp,
              text: "Move up",
            },
            {
              icon: "arrow-down",
              disabled: index + 1 === level.lessons.length,
              onClick: moveDown,
              text: "Move down",
            },
            {
              icon: "duplicate",
              onClick: duplicateLevel,
              text: "Duplicate",
            },
            {
              intent: Intent.DANGER,
              icon: "trash",
              text: "Delete",
              onClick: deleteIt,
            },
          ]}
        />
      </div>
      {isOpen(level.id) && (
        <div
          style={{
            paddingLeft: 16,
          }}
        >
          {level.lessons.map((lesson, index) => (
            <LessonEditor
              level={level}
              index={index}
              lesson={lesson}
              previousLevel={era.levels[era.levels.indexOf(level) - 1]}
              key={lesson.id}
            />
          ))}
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              padding: 4,
            }}
          >
            <div style={{ alignSelf: "baseline" }}>
              <Icon size={18} icon={"chevron-right"} />
            </div>
            <H4>
              <EditableText
                key={level.lessons.length}
                placeholder={"Add lesson ..."}
                onConfirm={(name) => {
                  level.lessons.push(newLesson(name));
                  update();
                }}
              />
            </H4>
          </div>
        </div>
      )}
    </div>
  );
}

export default LevelEditor;
