import React from "react";
import { Button, Menu, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { useListImages } from "../../../hooks/useListFiles";

type ImageListProps = { onSubmit: (filename: string) => void };

export function ImageList({ onSubmit }: ImageListProps) {
  const imgs = useListImages();
  return (
    <Popover2
      content={
        <Menu>
          {imgs.map(({ name }) => (
            <MenuItem text={name} key={name} onClick={() => onSubmit(name)} />
          ))}
        </Menu>
      }
    >
      <Button icon={"caret-down"} />
    </Popover2>
  );
}
