import React from "react";
import { Button, EditableText, Icon, Intent } from "@blueprintjs/core";
import { useEras } from "../../../../contexts/ErasContext";
import {
  QuizSaxophoneFingeringAnswersElement,
  SaxophoneNoteName,
  saxophoneNoteNames,
} from "../../../../GameElements";
import { ElementEditorProps } from "./ElementEditorProps";
import { SaxophoneFingeringDisplay } from "./SaxophoneFingeringDisplay";
import {
  TopRightActionsButton,
  useElementActions,
} from "./TopRightActionsButton";

function SaxophoneFingeringAnswersElementEditor(
  props: ElementEditorProps<QuizSaxophoneFingeringAnswersElement>,
) {
  const { update } = useEras();
  const actions = useElementActions(props);
  return (
    <TopRightActionsButton {...{ actions }}>
      <div
        style={{
          width: "100%",
          justifyContent: "stretch",
          alignItems: "stretch",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {props.element.answers.map((answer, index) => (
          <div
            key={index}
            style={{
              width: "50%",
              aspectRatio: 1,
              padding: 6,
              position: "relative",
            }}
          >
            <div style={{ width: "100%", flex: 1 }}>
              <SaxophoneFingeringDisplay {...answer.content} />
              <div style={{ display: "flex" }}>
                <EditableText
                  placeholder={"Note..."}
                  onConfirm={(t) => {
                    if (saxophoneNoteNames.includes(t as SaxophoneNoteName)) {
                      answer.content.noteName = t as SaxophoneNoteName;
                    }
                    update();
                  }}
                  defaultValue={answer.content.noteName}
                />
                -
                <EditableText
                  placeholder={"Alt..."}
                  onConfirm={(t) => {
                    answer.content.alternative = Number(t);
                    update();
                  }}
                  defaultValue={answer.content.alternative + ""}
                />
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: "50%",
              }}
            >
              <Icon
                onClick={() => {
                  answer.value = !answer.value;
                  update();
                }}
                intent={answer.value ? Intent.SUCCESS : Intent.DANGER}
                icon={answer.value ? "tick-circle" : "cross-circle"}
              />
            </div>
          </div>
        ))}
        {props.element.answers.length !== 2 && (
          <Button
            onClick={() => {
              props.element.answers.push({
                content: { noteName: "C5", alternative: 0 },
                value: true,
              });
              update();
            }}
          >
            Add Answer
          </Button>
        )}
      </div>
    </TopRightActionsButton>
  );
}

export default SaxophoneFingeringAnswersElementEditor;
