import React, { useState } from "react";
import { MenuItemProps } from "@blueprintjs/core";
import { SheetMusicContent } from "../../../../../GameElements";
import { TopRightActionsButton } from "../TopRightActionsButton";
import MusicSheetDisplay from "./MusicSheetDisplay";
import SheetMusicEditorDialog from "./SheetMusicEditorDialog";

const SheetMusicEditor = (props: {
  content: SheetMusicContent;
  setContent: (c: SheetMusicContent) => void;
  actions?: MenuItemProps[];
}) => {
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <>
      <TopRightActionsButton
        actions={[
          ...(props.actions || []),
          {
            text: "Edit",
            onClick: () => setPopupOpen(true),
            icon: "edit",
          },
        ]}
      >
        <MusicSheetDisplay content={props.content} />
      </TopRightActionsButton>
      {popupOpen && (
        <SheetMusicEditorDialog
          close={() => setPopupOpen(false)}
          setContent={props.setContent}
          content={props.content}
        />
      )}
    </>
  );
};
export default SheetMusicEditor;
