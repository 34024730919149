import React, { createContext, useContext } from "react";
import useLocalState from "../hooks/useLocalState";

export type Configuration = {
  imageServer: string;
  soundServer: string;
  deviceWidth: number;
  deviceHeight: number;
  darkMode: boolean;
};
const initialConfiguration = {
  imageServer:
    "https://storage.googleapis.com/go-saxo.appspot.com/public/app/assets/images",
  soundServer:
    "https://storage.googleapis.com/go-saxo.appspot.com/public/app/assets/sounds",
  deviceWidth: 240,
  deviceHeight: 500,
  darkMode: true,
};
const ConfigurationContext = createContext<{
  configuration: Configuration;
  setConfiguration: (c: Configuration) => void;
}>({
  configuration: initialConfiguration,
  setConfiguration: () => {},
});
export const ConfigurationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [configuration, setConfiguration] = useLocalState<Configuration>(
    "configuration-v2",
    initialConfiguration,
  );
  return (
    <ConfigurationContext.Provider
      value={{
        configuration,
        setConfiguration,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};
export const useConfiguration = () => useContext(ConfigurationContext);
