import React from "react";
import { EditableText, H4 } from "@blueprintjs/core";
import { useEras } from "../../../contexts/ErasContext";
import { Quiz } from "../../../GameElements";

type TextAnswersElementProps = { quiz: Quiz };

function TextAnswersElement({ quiz }: TextAnswersElementProps) {
  const { update } = useEras();

  return (
    <H4>
      <EditableText
        multiline
        confirmOnEnterKey
        placeholder={"Title..."}
        onConfirm={(t) => {
          quiz.title = t;
          update();
        }}
        defaultValue={quiz.title}
      />
    </H4>
  );
}

export default TextAnswersElement;
