import React from "react";
import { Button, EditableText, Icon, Intent } from "@blueprintjs/core";
import { useEras } from "../../../../contexts/ErasContext";
import { QuizTextAnswersElement } from "../../../../GameElements";
import { ElementEditorProps } from "./ElementEditorProps";
import {
  TopRightActionsButton,
  useElementActions,
} from "./TopRightActionsButton";

function TextAnswersElementEditor(
  props: ElementEditorProps<QuizTextAnswersElement>,
) {
  const { update } = useEras();
  const actions = useElementActions(props);

  return (
    <TopRightActionsButton {...{ actions }}>
      <div
        style={{
          width: "100%",
          justifyContent: "stretch",
          alignItems: "stretch",
          display: "flex",
          flexDirection: "column",
          gap: 6,
        }}
      >
        {props.element.answers.map((answer, index) => (
          <div style={{ display: "flex" }} key={"" + index}>
            <div style={{ width: "100%", flex: 1 }}>
              <EditableText
                multiline
                confirmOnEnterKey
                placeholder={"Body..."}
                onConfirm={(t) => {
                  answer.content = t;
                  update();
                }}
                defaultValue={answer.content}
              />
            </div>
            <Icon
              onClick={() => {
                answer.value = !answer.value;
                update();
              }}
              intent={answer.value ? Intent.SUCCESS : Intent.DANGER}
              icon={answer.value ? "tick-circle" : "cross-circle"}
            />
          </div>
        ))}
        <Button
          onClick={() => {
            props.element.answers.push({ content: "", value: true });
            update();
          }}
        >
          Add Answer
        </Button>
      </div>
    </TopRightActionsButton>
  );
}

export default TextAnswersElementEditor;
