import React from "react";
import { Button, Menu, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { useListSounds } from "../../../hooks/useListFiles";

type SoundListProps = { onSubmit: (filename: string) => void };

export function SoundList({ onSubmit }: SoundListProps) {
  const sounds = useListSounds();
  return (
    <Popover2
      content={
        <Menu>
          {sounds.map(({ name }) => (
            <MenuItem text={name} key={name} onClick={() => onSubmit(name)} />
          ))}
        </Menu>
      }
    >
      <Button icon={"caret-down"} />
    </Popover2>
  );
}
