import React from "react";
import { useConfiguration } from "../../../contexts/ConfigurationContext";
import { QuizEditorProps } from "./QuizEditorProps";
import QuizMoreButton from "./QuizMoreButton";

export const TopBar = ({ quiz, index, count, lesson }: QuizEditorProps) => {
  const {
    configuration: { darkMode },
  } = useConfiguration();
  return (
    <div
      style={{
        background: !darkMode ? "white" : "darkgrey",
        padding: 16,
        display: "flex",
        gap: 12,
        justifyItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          borderRadius: 12,
          background: "#dcdcdc",
          height: 12,
        }}
      >
        <div
          style={{
            background: !darkMode ? "#36b737" : "#2c962d",
            borderRadius: 12,
            minHeight: 12,
            width: ((index + 1) / count) * 100 + "%",
          }}
        />
      </div>
      <QuizMoreButton quiz={quiz} lesson={lesson} index={index} />
    </div>
  );
};
